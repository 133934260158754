const menu = [
  {
    title: 'Tailor-Made Travels',
    link: '/travels',
    items: [
      {
        title: 'European Odyssey',
        link: '/travels/european-odyssey',
      },
      {
        title: 'Luxury Body Boost',
        link: '/travels/luxury-body-boost',
      },
      {
        title: 'White Wonderland',
        link: '/travels/white-wonderland',
      },
    ],
  },
  {
    title: 'Latest Exclusivities',
    link: '/exclusivities',
    items: [
      {
        title: 'Gastronomy',
        link: '/exclusivities/gastronomy',
      },
      {
        title: 'Sports',
        link: '/exclusivities/motor-sports',
      },
      {
        title: 'Art & Fashion',
        link: '/exclusivities/art-and-fashion',
      },
      {
        title: 'Out Of This World',
        link: '/exclusivities/out-of-this-world',
      },
      {
        title: 'Wines & Spirits',
        link: '/exclusivities/wines-and-spirits',
      },
    ],
  },
  {
    title: 'Concierge Services',
    link: '/services',
  },
  {
    title: 'Your Travel Diary',
    link: '/book',
  },
  {
    title: 'About us',
    link: '/about-us',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];

export var login =  {
  title: 'Login',
  link: '/login',
};

export default menu;
