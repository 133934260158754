import topImage from '../assets/images/travels/concierge_services.png';
import parallaxImage from '../assets/images/travels/travels-parallax-background.webp'; //'../assets/images/services/services-parallax-background.webp'

import jetImage from '../assets/images/services/services-jet.webp';
import helicopterImage from '../assets/images/services/services-helicopter.webp';
import yatchImage from '../assets/images/services/services-yatch.webp';
import supercarImage from '../assets/images/services/services-supercar.webp';
import driverImage from '../assets/images/services/services-driver.webp';
import chaletImage from '../assets/images/services/services-chalet.webp';
import villaImage from '../assets/images/services/services-villa.webp';
import hotelImage from '../assets/images/services/services-hotel.webp';
import {
  chaletCarouselImages,
  chauffeurCarouselImages,
  helicopterCarouselImages,
  hotelCarouselImages,
  jetCarouselImages,
  supercarCarouselImages,
  villaCarouselImages,
  yatchCarouselImages,
} from './services-images';

const services = {
  title: 'Concierge Services',
  description:
    'Your time is precious. Let us consult our handpicked worldwide network to sort any travel request you might have. Leave your needs in our experts’ hands. We bet our reactivity will amaze you!',
  imageParallax: parallaxImage,
  imageTop: { nameFile: topImage, altText: 'City' },
  marginTop : 25,
  marginTopMobile : 22,
  choices: [
    {
      title: 'Private Jet',
      tagLink: 'private-jet',
      description:
        'Whether you are looking for the best empty leg deal or to fly around the world, we have selected the best partners so that we have your back any (& every) time. Fly private, after all it is as much about the journey than it is about the destination.',
      caroussel: jetCarouselImages,
      background: jetImage,
    },
    {
      title: 'Helicopter',
      tagLink: 'helicopter',
      description:
        'For a shorter transfer or an extravagant picnic on a glacier, whatever your needs might be, we have hand-picked the safest and most luxurious providers for your comfort and pleasure! There is nothing like views from a heli!',
      caroussel: helicopterCarouselImages,
      background: helicopterImage,
    },
    {
      title: 'Yacht',
      tagLink: 'yacht',
      description:
        'Yachting is an experience like no other. The feeling of being out at sea all alone before entering a port in style... But it has to be done right. Our carefully selected partners get it right every time!',
      caroussel: yatchCarouselImages,
      background: yatchImage,
    },
    {
      title: 'Supercar',
      tagLink: 'vintage-and-supercar',
      description:
        'Seeking some adrenaline, thrill and style? Let us rent you your dream sports (or vintage car) for a day to remember. [Private driver] Relax in the back of a luxury car. We have it all sorted for you. Your private driver is just one KLAVEM text away.',
      caroussel: supercarCarouselImages,
      background: supercarImage,
    },
    {
      title: 'Private Chalets',
      tagLink: 'chalets',
      description:
        'We have hunted down the most incredible Alpine chalets, visiting them so that we can be sure that you feel like you are stepping into White Wonderland every time that you choose KLAVEM.',
      caroussel: chaletCarouselImages,
      background: chaletImage,
    },
    {
      title: 'Private Villas',
      tagLink: 'private-villas',
      description:
        'We know that you adore the luxury and intimacy of a villa. Let us handpick the right one for you as we carefully screen through your criteria and find your perfect match.',
      caroussel: villaCarouselImages,
      background: villaImage,
    },
    {
      title: 'Hotel Bookings',
      tagLink: 'hotel-bookings',
      description:
        'There is just so much on offer these days. You can relax. We have shortlisted and tested each hotel that we offer so that you will never have a bad surprise. Just a wonderfully luxurious experience.',
      caroussel: hotelCarouselImages,
      background: hotelImage,
    },
    {
      title: 'Private Driver',
      tagLink: 'private-driver',
      description:
        'May you be away or at home, entrust one of our selected partners with your transportation. No matter how last minute, send us a message, we always do our best to find a solution.',
      caroussel: chauffeurCarouselImages,
      background: driverImage,
    },
  ],
};

export default services;
