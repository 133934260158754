import './menuyMainButton.styles.css';

const MenuyMainButton = ({ isOpen, onClick }) => {
  return (
    <div
      className={`lg:hidden menu-main-button-wrapper ${isOpen ? 'button-close' : ''}`}
      onClick={onClick}
    >
      <div className="menu-main-button-container-bar">
        <div className="menu-main-button-bar"></div>
        <div className="menu-main-button-bar"></div>
      </div>

      <div className="menu-main-button-container">
        <div className="menu-main-button-name"></div>
        <div className="menu-main-button-bar-gold"></div>
      </div>

      <div className="menu-main-button-container-bar">
        <div className="menu-main-button-bar inverse"></div>
        <div className="menu-main-button-bar inverse"></div>
      </div>
    </div>
  );
};

export default MenuyMainButton;
