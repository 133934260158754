import backgroundImage from '../assets/images/exclusivities/exclusivities-parallax-background.png';
import topImage from '../assets/images/exclusivities/exclusivities-parallax-top.webp';

import gastronomyThumbnail from '../assets/images/exclusivities/exclusivities-gastronomy-thumbnail.webp';
import gastronomyBarbecueCookingLesson from '../assets/images/exclusivities/exclusivities-gastronomy-details-barbecue-cooking-lesson.jpg';
import gastronomyCaviarTesting from '../assets/images/exclusivities/exclusivities-gastronomy-details-caviar-testing.jpg';
import gastronomyDinerAtChampagneCastle from '../assets/images/exclusivities/exclusivities-gastronomy-details-diner-at-champagne-castle.webp';
import gastronomyDinerWithNavajo from '../assets/images/exclusivities/exclusivities-gastronomy-details-diner-with-navajo.jpg';
import gastronomyPicnicOnSlopes from '../assets/images/exclusivities/exclusivities-gastronomy-details-picnic-on-slopes.jpg';
import gastronomyPrivateDinner3StarsMichelin from '../assets/images/exclusivities/exclusivities-gastronomy-details-private-dinner-3-stars-michelin.jpg';
import gastronomyPrivateDinnerMallorca from '../assets/images/exclusivities/exclusivities-gastronomy-details-private-dinner-mallorca.jpg';
import gastronomyPrivateDinnerOceanographicMuseum from '../assets/images/exclusivities/exclusivities-gastronomy-details-private-dinner-oceanographic-museum.jpg';
import gastronomyPrivateDinnerSushi3StarsMichelin from '../assets/images/exclusivities/exclusivities-gastronomy-details-private-dinner-sushi-3-stars-michelin.jpg';

import motorSportThumbnail from '../assets/images/exclusivities/exclusivities-motor-sport-thumbnail.webp';
import motorSportF1 from '../assets/images/exclusivities/exclusivities-motor-sport-details-f1.jpg';
import motorSportSuperCar from '../assets/images/exclusivities/exclusivities-motor-sport-details-supercar.jpg';
import motorSportHeliR44 from '../assets/images/exclusivities/exclusivities-motor-sport-details-heli-r44.jpg';
import motorSportHeliSki from '../assets/images/exclusivities/exclusivities-motor-sport-details-heli-ski.jpg';
import motorSportHeliFish from '../assets/images/exclusivities/exclusivities-motor-sport-details-heli-fish.jpg';
import motorSportIceDriving from '../assets/images/exclusivities/exclusivities-motor-sport-details-ice-driving.jpg';
import motorSportMeetCelebrity from '../assets/images/exclusivities/exclusivities-motor-sport-details-meet-celebrity.jpg';
import motorSportPrivateSlopeCourchevel from '../assets/images/exclusivities/exclusivities-motor-sport-details-private-slope-courchevel.jpg';
import motorSportSkiWithChampion from '../assets/images/exclusivities/exclusivities-motor-sport-details-ski-with-champion.jpg';
import motorSportSurfWithLegend from '../assets/images/exclusivities/exclusivities-motor-sport-details-surf-with-legend.jpg';
import motorSportAuroraBorealis from '../assets/images/exclusivities/exclusivities-motor-sport-details-aurora-borealis.jpg';

import artAndFashionThumbnail from '../assets/images/exclusivities/exclusivities-art-and-fashion-thumbnail.webp';
import artAndFashionCreateYourPerfume from '../assets/images/exclusivities/exclusivities-art-and-fashion-details-create-your-perfume.jpg';
import artAndFashionFashionWeekAfterParties from '../assets/images/exclusivities/exclusivities-art-and-fashion-details-fashion-week-after-parties.jpg';
import artAndFashionFashionWeek from '../assets/images/exclusivities/exclusivities-art-and-fashion-details-fashion-week.jpg';
import artAndFashionMeetWendyFederman from '../assets/images/exclusivities/exclusivities-art-and-fashion-details-meet-wendy-federman.jpg';
import artAndFashionOldQuarry from '../assets/images/exclusivities/exclusivities-art-and-fashion-details-old-quarry.jpg';

import outOfThisWorldThumbnail from '../assets/images/exclusivities/exclusivities-out-of-this-world-thumbnail.webp';
import outOfThisWorldAstronautTraining from '../assets/images/exclusivities/exclusivities-out-of-this-world-details-astronaut-training.jpg';
import outOfThisWorldSupersonicFlight from '../assets/images/exclusivities/exclusivities-out-of-this-world-details-fly-supersonic-fighter.jpg';
import outOfThisWorldSpaceStationFlight from '../assets/images/exclusivities/exclusivities-out-of-this-world-details-fly-to-space-station.jpg';
import outOfThisWorldZeroGravity from '../assets/images/exclusivities/exclusivities-out-of-this-world-details-zero-gravity.jpg';

import winesAndSpiritThumbnail from '../assets/images/exclusivities/exclusivities-wines-and-spirit-thumbnail.webp';
import winesAndSpiritMacallanDistillery from '../assets/images/exclusivities/exclusivities-wines-and-spirit-details-macallan-distillery.jpg';
import winesAndSpiritPetrusTasting from '../assets/images/exclusivities/exclusivities-wines-and-spirit-details-petrus-tasting-paris.jpg';
import winesAndSpiritTequilla from '../assets/images/exclusivities/exclusivities-wines-and-spirit-details-tequilla.jpg';
import winesAndSpiritWhiskyTastingIslay from '../assets/images/exclusivities/exclusivities-wines-and-spirit-details-whisky-tasting-islay.jpg';
import winesAndSpiritWhiskyTastingParis from '../assets/images/exclusivities/exclusivities-wines-and-spirit-details-whisky-tasting-paris.jpg';
import { useMediaQuery } from 'react-responsive';
import parallaxGradient from '../assets/images/travels/travels-parallax-gradient.webp';

const exclusivities = {
  title: 'Latest Exclusivities',
  description:
    'There is nothing better than setting off on adventures while knowing that no one else has access to them. That is why we love to negotiate for our members one-of-a-kind experiences usually unattainable to the public. Let’s make your dreams come true!',
  imageParallax: backgroundImage,
  linearGradient: parallaxGradient,
  imageTop: { nameFile: topImage, altText: 'Clouds' },
  imageWeight: '83%',
  paddingTop: '20vh',
  marginTop: 20,
  marginTopMobile: 7,
  categories: [
    {
      tabTitle: 'Gastronomy',
      title: 'Gastronomy Experience',
      description:
        'Ever dreamt about picnicking on a mountain top with a Michelin chef or dining in a champagne castle unopened to the public? Let our authentic experiences mind-blow your taste buds.',
      link: 'gastronomy',
      thumbnail: gastronomyThumbnail,
      details: [
        {
          title: 'Get a private dinner from 3-star Michelin chef',
          description:
            'A unique chance to experience a dinner like no other. Served in an incredible private setting handpicked by KLAVEM just for you, with a menu tailor-made to your liking by one of France’s most upcoming chefs. Why don’t you drop by the kitchen first and get a behind-the-scenes look too?',
          image: gastronomyPrivateDinner3StarsMichelin,
        },
        {
          title: 'Caviar tasting in private jet',
          description:
            'Looking to take your taste buds for an epic ride? Enjoy an experience reserved to the selected few: an inflight caviar tasting. As your private jet takes off, your taste buds will go to the next level. Let our caviar expert take you through a tasting like no other.',
          image: gastronomyCaviarTesting,
        },
        {
          title: 'Dine at a Champagne castle unopened to the public',
          description:
            "Take part in a once-in-a-lifetime opportunity to dine in a Medieval Champagne castle at the owner's own table. Tour the fields and facilities before delighting your palate with a Champagne tasting. Fond of hunting? Set off with the owner and hunt on his grounds before dinner!",
          image: gastronomyDinerAtChampagneCastle,
        },
        {
          title: 'Dine with a Navajo Code Talker in South-West USA',
          description:
            'Enjoy the company of Peter MacDonald, Sr, Native American politician. Born in Arizona, he served the U.S. Marine Corps in World War II as a Navajo Code Talker. This is a truly unique opportunity to hear fascinating stories, inquire about his interesting life, and enhance your understanding of the Navajo people and history.',
          image: gastronomyDinerWithNavajo,
        },
        {
          title: 'Dine in amazing Mallorquean gardens privatized for you',
          description:
            'Enjoy a tour of the amazing Gardens of Alfabia & their Rococo house with the owner herself as we privatize it all for you. Head to your intimate dinner set in a marvelous spot in the Gardens. A local chef cooked tasty traditional dishes and paired them with delightful Mallorquean wines. Let it be a night to remember!',
          image: gastronomyPrivateDinnerMallorca,
        },
        {
          title:
            'Witness prodigy trained by 3-Michelin star Master make sushi before your eyes',
          description:
            'A young Franco-Japanese prodigy embodying  the ultimate contemporary Japanese cuisine, this chef is now a renowned sushi master in France.  He developed exclusively for KLAVEM members an "out-of-this-world" menu where he opens the doors of his culinary universe for a unique transcendental culinary experience...',
          image: gastronomyPrivateDinnerSushi3StarsMichelin,
        }, 
        {
          title: 'Enjoy a gourmet picnic on the slopes with Michelin-star chef',
          description:
            'Ski with a Michelin-starred chef in Courchevel before enjoying a gourmet picnic on the mountains, making you the envy of the slopes. End this incredible experience with champagne and a demonstration from the chef cooking one of his menu specialties.',
          image: gastronomyPicnicOnSlopes,
        },
        {
          title:
            'Get a barbecue lesson with the legendary chef Francis Mallmann',
          description:
            "Experience an incredible 6-hour cooking class in a Provence vineyard with the Patagonian chef famous worldwide for his barbecuing and feature in Netflix's Chef's Table. Class includes cooking in ashes and earth pit, grill, cast iron plancha and whole lamb on cross lunch by the fires.",
          image: gastronomyBarbecueCookingLesson,
        },
      ],
    },
    {
      index: 0,
      tabTitle: 'Sports',
      title: 'Sports Experience',
      description:
        'Get an adrenaline rush like never before. Rediscover your passion or fall in love with a new one thanks to one of our thrilling exclusive (motor)sports experiences.',
      link: 'motor-sports',
      thumbnail: motorSportThumbnail,
      details: [
        {
          title: 'Drive an F1 on a GP racetrack',
          description:
            'Step into the shoes of a Formula 1 driver. Pick your track, decide on a number of laps and choose a car. With the support of our experienced partners, it is time for an experience full of adrenaline and thrill!',
          image: motorSportF1,
        },
        {
          title: 'View aurora borealis from a remote lodge in Alaska',
          description:
            "Experience the beauties of Denali's remoteness as you enjoy the luxuries of your private chalet. That accommodation is more than a place to stay, it’s an opportunity to “Experience Grand”. Be on the watch out for the mindblowing Northern lights from the flanks of North America’s highest mountain. You have to see them at least once in your life!",
          image: motorSportAuroraBorealis,
        },
        {
          title: 'Driving A Supercar Through The Swiss Alps to Como',
          description:
            'How about driving a Ferrari 488 GTB, a Porsche 911 Turbo S Cabriolet or a Maserati Gran Cabrio MC Ferrari through the Swiss Alps? you can do just that, taking a supercar on hairpin turns in the Alps, staying in five-star hotels and indulging in the lap of Alpine luxury.',
          image: motorSportSuperCar,
        },
        {
          title: "Heli-fish in British Columbia's most remote streams",
          description:
            'Experience an unforgettable guided heli-fly fishing adventure in some of the most remote rivers and lakes in the world. Little known and virtually untouched, these fishing spots are accessible only by helicopter, offering keen anglers exclusive access to prime fishing locations.',
          image: motorSportHeliFish,
        },
        {
          title: 'Meet an American sports celebrity',
          description:
            'Jump on the incredible opportunity to exchange one-on-one with a New York sports celebrity. Let us organize a dinner like no other in an incredible handpicked location.',
          image: motorSportMeetCelebrity,
        },
        {
          title: 'Ski alongside a former champion',
          description:
            'Take your ski experience to another level. Race down the slopes alongside one or more ex professional ski champions. See how fast you can actually go, benefit from their advice and hear firsthand all of their crazy stories.',
          image: motorSportSkiWithChampion,
        },
        {
          title: 'Surf with a legend on a custom-made board in California',
          description:
            'Off to Del Mar, a laid-back beach town where surf is available all year round, for your private surf lesson with a legend. Ride the waves like never before with a surf board tailor-made just for you.',
          image: motorSportSurfWithLegend,
        },
        {
          title: 'Escape from the tracks. Heliski in the Alps',
          description:
            'Have you ever given a go to heliski? What about heliskiing from Zermatt to Chamonix, never skiing down the same slope? Or have you heard of heliskiing in Greenland or Iceland? If looking for the ultimate experience, let us book you a yacht so that you ski on mountains during the day and sleep on the ocean by night.',
          image: motorSportHeliSki,
        },
        {
          title: 'Pilot a supercar on ice in Lapland',
          description:
            'For an exhilarating experience, drive supercars on ice tracks recreating F1 circuits in the winter wonderland that is Lapland. We can even privatize them for you! For more James Bond-like action extend your trip and drive trucks on Icelandic ice.',
          image: motorSportIceDriving,
        },
        {
          title: 'Pilot a R44 helicopter in the French Alps',
          description:
            'Imagine yourself at the controls of a helicopter. Get a 30mn pre-flight briefing with your instructor before taking control for a full hour. The Robinson R44  is all yours! It is a unique and fantastic experience but explanations come nowhere close to the experience itself - it is simply something you have to try.',
          image: motorSportHeliR44,
        },
        {
          title: 'Ski down a privatized slope in Courchevel',
          description:
            "It's time to feel the thrill of being the only one to race down some of Courchevel's slopes at nightfall. Your ski guide and his torchlights will guide you all the way to champagne and fireworks so that you live a true VIP experience.",
          image: motorSportPrivateSlopeCourchevel,
        },
      ],
    },
    {
      tabTitle: 'Wines & Spirits',
      title: 'Wines & Spirits Experience',
      description:
        'Take your wellness rituals to the next level. Test the latest techniques. Stay in the most exclusive wellness centers. Make time for what is important. Choose the best for your health.',
      link: 'wines-and-spirits',
      thumbnail: winesAndSpiritThumbnail,
      details: [
        {
          title: 'Privatize the Macallan Scottish distillery',
          description:
            "Get that iconic distillery all to yourself for half a day. Taste their best bottles and uncover all of that magical place's secrets",
          image: winesAndSpiritMacallanDistillery,
        },
        {
          title:
            'Become a Tequila & Mezcal connoisseur with our Maestro Tequilero',
          description:
            'Discover five tequilas & two mezcals made by a Mexican ultra-luxury distillery set to making the smoothest in the world. Paired with food & hosted by a maestro tequilero, that is the perfect recipe for an evening to remember',
          image: winesAndSpiritTequilla,
        },
        {
          title: 'Go on the ultimate peated whisky tasting in Islay',
          description:
            'Set off for the land of whisky: Scotland, and most precisely to the most iconic island: Islay. It is time to delight all your senses with incredible pitted whiskies that you will discover during exclusive tastings. Vintage cars, mansions & wonderful landscapes will amaze you along the way.',
          image: winesAndSpiritWhiskyTastingIslay,
        },
        {
          title: 'Taste Petrus with no one around but a sommelier in Paris',
          description:
            "Take part in a timeless tasting of 5 exceptional vintages of the world's most sought-after wine. Your sommelier will walk you through the Petrus 2000, 2004, 2009, 2015 & 2017 in the incredible setting that is an old Parisian cellar.",
          image: winesAndSpiritPetrusTasting,
        },
        {
          title: 'Opt for the most private whisky tasting in Paris',
          description:
            "Taste whiskies that only a handful have had the pleasure to put their lips on, including the world's most expensive whisky...",
          image: winesAndSpiritWhiskyTastingParis,
        },
      ],
    },
    {
      tabTitle: 'Art & Fashion',
      title: 'Art & Fashion Experience',
      description:
        'Experience art & fashion just like artists and designers do. Let us get you behind-the-scenes so that you discover these two worlds all over again. It’s time to get creative!',
      link: 'art-and-fashion',
      thumbnail: artAndFashionThumbnail,
      details: [
        {
          title: 'Create your perfume with Dior roses',
          description:
            'Imagine yourself surrounded by thousands of pink roses. It is time for you to meet one on one with the cultivator and the perfumer of Dior roses to create your own perfume. The field is all yours.',
          image: artAndFashionCreateYourPerfume,
        },
        {
          title: 'Get invited to the NYC Fashion week',
          description:
            'Let us grant you access to New York fashion shows so that you see firsthand what amazing trends are coming next. It’s time for you to enter the fashion world through the VIP door!',
          image: artAndFashionFashionWeek,
        },
        {
          title:
            'Meet Wendy Federman NYC Brodway producer and visit the backstage',
          description:
            "Do not miss the chance to meet one of Broadway's most iconic producers! Wendy Federman has produced dozens of shows, including King Lear, Moulin Rouge and many others. After watching one of the best shows in the world, from VIP seats, what is more incredible than getting the chance to exchange backstage with the producer?",
          image: artAndFashionMeetWendyFederman,
        },
        {
          title: 'Get invited to the NYC Fashion week afterparties',
          description:
            'Longing to dive into the world of New York fashion artists and designers? What better occasion to exchange with them than at a Fashion Week after-party? Let us grant you access to VIP-only events.',
          image: artAndFashionFashionWeekAfterParties,
        },
        {
          title: 'Privatize an old quarry turned amazing art exhibit',
          description:
            'Feel the grandeur of an old limestone quarry in Les Baux-de-Provence, France, as you get it all to yourself. Be inspired by the digital art exhibition running along the walls...and by the glass of bubbles in your hands!',
          image: artAndFashionOldQuarry,
        },
      ],
    },
    {
      tabTitle: 'Out Of This World',
      title: 'Out Of This World Experience',
      description:
        'Beware, this section of exclusivities is not for the fainthearted. Who else than the bravest adventurer would wish to venture into space or at the bottom of the ocean?',
      link: 'out-of-this-world',
      thumbnail: outOfThisWorldThumbnail,
      details: [
        {
          title: 'Train like an astronaut',
          description:
            'Join a never-seen-before 4-day VIP program in France or in the USA. Let top trainers train you and take you on zero-g and high-g flights. Experience a memorable space food lab workshop and tasting with Michelin Star Chef Alain Ducasse, responsible for special meals on the ISS. Price on request.',
          image: outOfThisWorldAstronautTraining,
        },
        {
          title: 'Experience zero gravity',
          description:
            'Experience the incredible sensations offered by weightlessness aboard the Airbus A310. Accompanied by astronaut Jean-François Clervoy, you will take part in exhilarating onboard activities that you will never have dreamt of.',
          image: outOfThisWorldZeroGravity,
        },
        {
          title: 'Fly to the International Space Station',
          description:
            'Yes, it is actually possible. You can travel 10 days to the International Space Station, orbiting the Earth in a SpaceX Crew Dragon, and conducting meaningful research and outreach projects alongside astronauts. Choose an experience that only a handful of individuals will ever live.',
          image: outOfThisWorldSpaceStationFlight,
        },
        {
          title: 'Fly a supersonic fighter jet',
          description:
            'Learn to fly a Lockheed F-104, the “missile with a man in it”. Discover the first aircraft to simultaneously hold the world speed and altitude records. During this adventure right out of a Top Gun movie, experience the thrill of g forces similar to a space launch. Price on request.',
          image: outOfThisWorldSupersonicFlight,
        },
      ],
    },
  ],
};

export default exclusivities;
