import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import Page from "../components/Page";

import video from '../assets/video/klavem-intro.mp4';

const HomePage = () => {
  return (
    <Page className="flex flex-col items-center h-screen overflow-hidden">
      <video className="mt-0 w-[175%] md:w-[60vw] max-w-4xl" src={video} autoPlay muted playsInline />

      <motion.p
        className="btn text-xs py-2 px-4 font-normal"
        initial={{ opacity: 0, transform: 'scale(0.5)' }}
        animate={{ opacity: 1, transform: 'scale(1)' }}
        transition={{ duration: 0.5, delay: 8 }}
      >
        <Link to="/home">
          DIVE IN
        </Link>
      </motion.p>
    </Page>
  );
};

export default HomePage;
