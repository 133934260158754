import PageWithHeader from '../components/PageWithHeader';


const WorkInProgressPage = () => {
  return (
    <PageWithHeader className="flex items-center h-screen overflow-hidden workinprogress">
      <h1>Apologies, our webpage is undergoing improvements
          <br/> to unveil exciting new destinations for your enjoyment</h1>

    </PageWithHeader>
  );
};

export default WorkInProgressPage;
