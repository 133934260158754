import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Dashboard from './pages/Dashboard';
import HomePage from './pages/Home';
import WorkInProgressPage from './pages/WorkInProgress'
import Travel from './pages/Travel';
import TravelDetail from './pages/TravelDetails';
import Exclusivities from './pages/Exclusivities';
import ExclusivitiesDetail from './pages/ExclusivitiesDetail';
import Service from './pages/Service';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Greeting from './pages/Greeting';
import Book from './pages/Book';

import TransitionLayout from './components/TransitionLayout';
import Login from './pages/Login';

function App() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/travels/work-in-progress" element={<WorkInProgressPage />}/>
        <Route path="/login" element={<Login />} />

        <Route element={<TransitionLayout />}>
          <Route path="/home" element={<Dashboard />} />
          <Route path="/travels" element={<Travel />} />

          <Route path="/travels/:destination" element={<TravelDetail />} />

          <Route
            path="/exclusivities/:exclusivityName"
            element={<ExclusivitiesDetail />}
          />
          <Route path="/exclusivities" element={<Exclusivities />} />

          <Route
            path="/exclusivities/:exclusivityName/details"
            element={<ExclusivitiesDetail />}
          />

          <Route path="/services" element={<Service />} />

          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/book" element={<Book />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/greeting" element={<Greeting />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
