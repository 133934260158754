import rivieraBackgroundImage from '../assets/images/travels/travels-french-riviera-getaway-parallax-background.mp4';
import rivieraTopImage from '../assets/images/travels/travels-french-riviera-getaway-parallax-top.webp';

import luxuryBackgroundImage from '../assets/images/travels/travels-luxury-body-boost-parallax-background.mp4';
import luxuryTopImage from '../assets/images/travels/travels-luxury-body-boost-parallax-top.webp';

import wonderlanBackgroundImage from '../assets/images/travels/travels-white-wonderland-parallax-background.mp4';
import wonderlanTopImage from '../assets/images/travels/travels-white-wonderland-parallax-top.webp';

import parallaxGradient from '../assets/images/travels/travels-parallax-gradient.webp';
import parallawWhiteWonderlandGradient from '../assets/images/travels/travels-white-wonderland-parallax-gradient.png';

import {
  rivieraAixImages,
  rivieraCannesImages,
  rivieraLuberonImages,
  rivieraMonacoImages,
  rivieraSaintTropezImages,
  rivieraVarImages,
  luxuryThailandeImages,
  luxuryGermanyImages,
  luxuryGreeceImages,
  luxurySpainImages,
  luxurySwitzerlandImages,
  wonderlandCourchevelImages,
  wonderlandMegeveImages,
  wonderlandSaintMoritzImages,
  wonderlandValIsereImages,
  wonderlandVerbierImages,
  wonderlandZermattImages,
} from './travelsImages';

export const travelDetails = {
  'european-odyssey': {
    title: 'European Odyssey',
    description:
      'Explore South of France, Solène & Sébastien’s birthplace. From the deep heart of Provence to Monaco going via St-Tropez, we have hunted all of the best spots. With our handpicked locations & exclusivities, you will truly (re)discover this region like never before. It is time for you to have access to our much-coveted list of hidden gems & VIP-only parties.',
    linkText: 'SOUNDS INCREDIBLE? LET’S TALK',
    imageParallax: rivieraBackgroundImage,
    linearGradient: parallaxGradient,
    imageTop: { nameFile: rivieraTopImage, altText: 'Sea border' },
    paddingTop: '0',
    destinations: [
      {
        choices: [
          {
            index: '0',
            title: 'Monaco',
            description:
              'We might not be able to guarantee that everything will be in black & white, but believe us, your stay will feel exactly like a Grace Kelly movie. The glamour, the luxury, the VIP feel. Solène’s years living in the Principality will ensure that it will all be there.',
            tagLink: 'monaco',
            caroussel: rivieraMonacoImages,
          },
          {
            index: '1',
            title: 'Saint-Tropez',
            description:
              'The hippest sunspot is calling. The white sand of Pampelonne is awaiting your footprints. The most exclusive clubs are expecting your dance moves. Pack your bags for a stay allying unwinding moments and never-ending evenings.',
            tagLink: 'saint-tropez',
            caroussel: rivieraSaintTropezImages,
          },
          {
            index: '2',
            title: 'Cannes',
            description:
              'Cannes is a place like no other. It is the archetype of the Riveria “art de vivre”. It is time to jump on a boat, sail along the coast, feel the sea breeze in your air, sip on a glass of bubbles and hit the dance floor before resting your head in an 360° sea view villa.',
            tagLink: 'cannes',
            caroussel: rivieraCannesImages,
          },
        ],
      },
      {
        choices: [
          {
            index: '3',
            title: 'Aix & Marseille',
            description:
              'Aix-en-Provence, the elegant, the classic, the historic. Marseille, the authentic, the eclectic, the proud. Both so and yet so similar in all that they have to offer around gastronomy, art and culture. Discover one, explore the other. Compare, contrast and fall in love.',
            tagLink: 'aix-en-provence-marseille',
            caroussel: rivieraAixImages,
          },
          {
            index: '4',
            title: 'Luberon',
            description:
              'Allow us to take you back in time. A time when most cars in Provence were colorful 2CVs. A time when vibrant food markets offering local produce were all that we had. The small quaint villages of Luberon are the perfect setting, so are you in?',
            tagLink: 'luberon',
            caroussel: rivieraLuberonImages,
          },
          {
            index: '5',
            title: 'Var',
            description:
              'Discover what the heart Provence is like behind closed doors. Get an exclusive glimpse at how rosé is concocted discussing with small winemakers. Acquire new skills making local produce with local masters. It’s time to indulge, explore and wonder.',
            tagLink: 'var',
            caroussel: rivieraVarImages,
          },
        ],
      },
    ],
  },
  'luxury-body-boost': {
    title: 'Luxury Body Boost',
    description:
      'Unwind in the most luxurious wellness centers. Take the best care of your health while feeling worlds away from daily life. May you be seeking a detox, preventative aging, stress management, weight loss or anything else, you can trust us. We have shortlisted the best of the best so that you don’t have to.',
    linkText: 'SOUNDS INCREDIBLE? LET’S TALK',
    imageParallax: luxuryBackgroundImage,
    linearGradient: '',
    imageTop: { nameFile: luxuryTopImage, altText: 'Buddha statues' },
    paddingTop: '18vh',
    destinations: [
      {
        choices: [
          {
            index: '0',
            title: 'Thailand',
            description:
              'Surrounded by the amazing tropical jungle of Koh Samui, right by a marvelous white sand beach & turquoise waters, Kamalaya is a top-notch wellness sanctuary. Delight in amazing healthy food, meet with monks and experience a holistic health journey far from everything.',
            tagLink: 'thailande',
            caroussel: luxuryThailandeImages,
          },
          {
            index: '1',
            title: 'Germany',
            description:
              'Nestled in idyllic Black Forest, we tested and fell in love with Brenners Park’s elegance and serenity. Their independent doctors offer aesthetic medicine & dentistry, holistic medicine, medical fitness and nutrition. The Beckhams trust them and that says it all.',
            tagLink: 'germany',
            caroussel: luxuryGermanyImages,
          },
          {
            index: '2',
            title: 'Greece',
            description:
              'Euphoria Retreat is a well-kept secret. This holistic destination spa in the heart of the wild Peloponnese will whisk you back in time. Suites evoke Ancient Greek temples and terracotta roofs stand out against the Greek blue sky and the pine forest. It is time to connect you with the healing power of nature.',
            tagLink: 'greece',
            caroussel: luxuryGreeceImages,
          },
          {
            index: '3',
            title: 'Spain',
            description:
              'May the Mediterranean sea breeze loosen your every nerve as you stand in an incredibly modern center wellness at the forefront of medical advances. Whatever your goals are, the SHA adapts its Method so that you live longer and better.',
            tagLink: 'spain',
            caroussel: luxurySpainImages,
          },
          {
            index: '4',
            title: 'Switzerland',
            description:
              'Standing strong on a mountain above Lake Lucerne, the Waldhotel Bürgenberg is an essential part of a resort that Chaplin & Audrey Hepburn loved. May it be for diagnostic, therapeutic or rehabilitation services, their Prevention & Health Programmes make it a world-class retreat.',
            tagLink: 'switzerland',
            caroussel: luxurySwitzerlandImages,
          },
        ],
      },
    ],
  },
  'white-wonderland': {
    title: 'White Wonderland',
    description:
      'Enter a world of white fairytale. Spend magical days uncovering the best secrets that European skiing has to offer. May you wish to (heli)-ski like crazy (with a ski champion?) or just make the most of our (fun & glamorous) exclusivities, let us take your winter holidays to the next level. There is just nothing like KLAVEM snow holidays.',
    linkText: 'SOUNDS INCREDIBLE? LET’S TALK',
    imageParallax: wonderlanBackgroundImage,
    linearGradient: parallawWhiteWonderlandGradient,
    imageTop: { nameFile: wonderlanTopImage, altText: 'Mountains' },
    paddingTop: 50,
    destinations: [
      {
        country: 'France',
        choices: [
          {
            index: '0',
            title: 'Courchevel 1850',
            description:
              'Experience our addictive concoction allying the glitziest resort’s luxuries and our exclusivities. Taste true thrills as we privatize a ski slope solely for you. Let us add a couple drops of James Bond feels and take you ice diving. The cherry on top? How about heliskiing in Italy after picnicking on a mountain top with a Michelin chef?',
            tagLink: 'courchevel',
            caroussel: wonderlandCourchevelImages,
          },
          {
            index: '1',
            title: 'Megève',
            description:
              'Choose Megève for its great gastronomic scene and spice up your stay with our exclusivities. Can we tempt you with a breathtaking helicopter tour over the Mont-Blanc? Or will you feel more adventurous and wish to go on an aerobatic flight?',
            tagLink: 'megève',
            caroussel: wonderlandMegeveImages,
          },
          {
            index: '2',
            title: "Val d'Isère",
            description:
              'Fall in love with a station quickly becoming the hippest spot in Europe. Our exclusivities will play a role, there is no doubt. After all, what is cooler than experiences such as ice driving or ice diving?',
            tagLink: 'val-d-isere',
            caroussel: wonderlandValIsereImages,
          },
        ],
      },
      {
        country: 'Switzerland',
        choices: [
          {
            index: '3',
            title: 'Verbier',
            description:
              'Offering the best heli off-piste skiing, Verbier is both athletic and luxurious. Let us take these two aspects to the ultimate level. It is not every day that you are able to find a 4-chalets property or experience things such as heli paragliding alongside stuntmen…',
            tagLink: 'verbier',
            caroussel: wonderlandVerbierImages,
          },
          {
            index: '4',
            title: 'St Moritz',
            description:
              'Set off for old-school ski holidays right out of a 60s movie, staying in the station’s iconic palaces and experiencing things you would never have thought of such as skiing behind a horse or privatizing an Olympic bob sled track.',
            tagLink: 'st-moritz',
            caroussel: wonderlandSaintMoritzImages,
          },
          {
            index: '5',
            title: 'Zermatt',
            description:
              'Nestled at the foot of the Matterhorn, Zermatt offers unequaled Alpine views. Why not let our exclusivities turn unique into overkill? Admire the station from a helicopter or what about… from a fighter jet…?',
            tagLink: 'zermatt',
            caroussel: wonderlandZermattImages,
          },    
        ],
      },
    ],
  },
};
