import axios from 'axios';

const KLAVEM_API =
  (process.env.REACT_APP_API_URL ?? 'http://localhost:8000') + '/api';

// Klavem API instance
const klavemApi = axios.create({
  baseURL: KLAVEM_API,
});

/**
 * Make a login request to Klavem API
 *
 * @param {String} accessCode
 */
export const login = async (accessCode) =>
  (await klavemApi.post('/login', { accessCode }))?.data;

/**
 * Send a contact request
 *
 * @param {Object} the request data
 */
export const contactRequest = async (request) =>
  (await klavemApi.post('/contact-request', request))?.data;
