import Header from "./Header";


export default function PageWithHeader({ className = "", style = {}, children }) {
  return (
    <div style={style}>
      <Header />

      <main className={`pt-0 pb-24 px-8 mx-auto lg:px-16 h-full max-w-5xl ${className}`}>
        {children}
      </main>
    </div>
  )
}
