/**
 * --------------------------------------
 * FRENCH RIVIERA - AIX
 * --------------------------------------
 */
import rivieraAixImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-1.webp';
import rivieraAixImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-2.webp';
import rivieraAixImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-3.webp';
import rivieraAixImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-4.webp';
import rivieraAixImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-5.webp';
import rivieraAixImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-6.webp';
import rivieraAixImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-7.webp';
import rivieraAixImage8 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-8.webp';
import rivieraAixImage9 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-9.webp';
import rivieraAixImage10 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-aix-image-10.webp';

/**
 * --------------------------------------
 * FRENCH RIVIERA - CANNES
 * --------------------------------------
 */
import rivieraCannesImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-1.webp';
import rivieraCannesImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-2.webp';
import rivieraCannesImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-3.webp';
import rivieraCannesImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-4.webp';
import rivieraCannesImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-5.webp';
import rivieraCannesImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-6.webp';
import rivieraCannesImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-7.webp';
import rivieraCannesImage8 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-cannes-image-8.webp';

/**
 * --------------------------------------
 * FRENCH RIVIERA - LUBERON
 * --------------------------------------
 */
import rivieraLuberonImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-1.webp';
import rivieraLuberonImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-2.webp';
import rivieraLuberonImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-3.webp';
import rivieraLuberonImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-4.webp';
import rivieraLuberonImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-5.webp';
import rivieraLuberonImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-6.webp';
import rivieraLuberonImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-luberon-image-7.webp';

/**
 * --------------------------------------
 * FRENCH RIVIERA - MONACO
 * --------------------------------------
 */
import rivieraMonacoImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-1.webp';
import rivieraMonacoImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-2.webp';
import rivieraMonacoImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-3.webp';
import rivieraMonacoImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-4.webp';
import rivieraMonacoImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-5.webp';
import rivieraMonacoImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-6.webp';
import rivieraMonacoImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-monaco-image-7.webp';

/**
 * --------------------------------------
 * FRENCH RIVIERA - SAINT TROPEZ
 * --------------------------------------
 */
import rivieraSaintTropezImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-1.webp';
import rivieraSaintTropezImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-2.webp';
import rivieraSaintTropezImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-3.webp';
import rivieraSaintTropezImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-4.webp';
import rivieraSaintTropezImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-5.webp';
import rivieraSaintTropezImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-6.webp';
import rivieraSaintTropezImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-7.webp';
import rivieraSaintTropezImage8 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-st-tropez-image-8.webp';

/**
 * --------------------------------------
 * FRENCH RIVIERA - VAR
 * --------------------------------------
 */
import rivieraVarImage1 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-1.webp';
import rivieraVarImage2 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-2.webp';
import rivieraVarImage3 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-3.webp';
import rivieraVarImage4 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-4.webp';
import rivieraVarImage5 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-5.webp';
import rivieraVarImage6 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-6.webp';
import rivieraVarImage7 from '../assets/images/travels/travels-french-riviera-gateway-caroussel-var-image-7.webp';

/**
 * --------------------------------------
 * LUXURY BODY BOOST - GERMANY
 * --------------------------------------
 */
import luxuryGermanyImage1 from '../assets/images/travels/travels-luxury-body-boost-caroussel-germany-image-1.webp';
import luxuryGermanyImage2 from '../assets/images/travels/travels-luxury-body-boost-caroussel-germany-image-2.webp';
import luxuryGermanyImage3 from '../assets/images/travels/travels-luxury-body-boost-caroussel-germany-image-3.webp';
import luxuryGermanyImage4 from '../assets/images/travels/travels-luxury-body-boost-caroussel-germany-image-4.webp';
import luxuryGermanyImage5 from '../assets/images/travels/travels-luxury-body-boost-caroussel-germany-image-5.webp';

/**
 * --------------------------------------
 * LUXURY BODY BOOST - THAILANDE
 * --------------------------------------
 */
 import luxuryThailandeImage1 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-2.jpg';
 import luxuryThailandeImage2 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-1.jpg';
 import luxuryThailandeImage3 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-3.jpg';
 import luxuryThailandeImage4 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-4.jpg';
 import luxuryThailandeImage5 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-5.png';
 import luxuryThailandeImage6 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-6.png';
 import luxuryThailandeImage7 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-7.jpg';
 import luxuryThailandeImage8 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-8.jpg';
 import luxuryThailandeImage9 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-9.png';
 import luxuryThailandeImage10 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-10.png';
 import luxuryThailandeImage11 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-11.jpg';
 import luxuryThailandeImage12 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-12.jpg';
 import luxuryThailandeImage13 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-13.jpg';
 import luxuryThailandeImage14 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-13.png';
 import luxuryThailandeImage15 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-14.jpg';
 import luxuryThailandeImage16 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-15.jpg';
 import luxuryThailandeImage17 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-16.png';
 import luxuryThailandeImage18 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-17-webp';
 import luxuryThailandeImage19 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-18.jpg';
 import luxuryThailandeImage20 from '../assets/images/travels/travels-luxury-body-boost-caroussel-thailande-image-20.jpg';


 /**
 * --------------------------------------
 * LUXURY BODY BOOST - GREECE
 * --------------------------------------
 */
import luxuryGreeceImage1 from '../assets/images/travels/Greece 1.jpg';
import luxuryGreeceImage2 from '../assets/images/travels/Greece 2.jpg';
import luxuryGreeceImage3 from '../assets/images/travels/Greece 3.jpg';
import luxuryGreeceImage4 from '../assets/images/travels/Greece 4.jpg';
import luxuryGreeceImage5 from '../assets/images/travels/Greece 5.jpg';
import luxuryGreeceImage6 from '../assets/images/travels/Greece 6.jpg';
import luxuryGreeceImage7 from '../assets/images/travels/Greece 7.jpg';
import luxuryGreeceImage8 from '../assets/images/travels/Greece 8.jpg';
import luxuryGreeceImage9 from '../assets/images/travels/Greece 9.jpg';
import luxuryGreeceImage10 from '../assets/images/travels/Greece 10.jpg';
import luxuryGreeceImage11 from '../assets/images/travels/Greece 11.jpg';
import luxuryGreeceImage12 from '../assets/images/travels/Greece 12.jpg';
import luxuryGreeceImage13 from '../assets/images/travels/Greece 13.jpg';
import luxuryGreeceImage14 from '../assets/images/travels/Greece 14.jpg';
import luxuryGreeceImage15 from '../assets/images/travels/Greece 15.jpg';
import luxuryGreeceImage16 from '../assets/images/travels/Greece 16.jpg';
import luxuryGreeceImage17 from '../assets/images/travels/Greece 17.jpg';
import luxuryGreeceImage18 from '../assets/images/travels/Greece 18.jpg';

/**
 * --------------------------------------
 * LUXURY BODY BOOST - SPAIN
 * --------------------------------------
 */
import luxurySpainImage1 from '../assets/images/travels/travels-luxury-body-boost-caroussel-spain-image-1.webp';
import luxurySpainImage2 from '../assets/images/travels/travels-luxury-body-boost-caroussel-spain-image-2.webp';
import luxurySpainImage3 from '../assets/images/travels/travels-luxury-body-boost-caroussel-spain-image-3.webp';
import luxurySpainImage4 from '../assets/images/travels/travels-luxury-body-boost-caroussel-spain-image-4.webp';
import luxurySpainImage5 from '../assets/images/travels/travels-luxury-body-boost-caroussel-spain-image-5.webp';

/**
 * --------------------------------------
 * LUXURY BODY BOOST - SWITZERLAND
 * --------------------------------------
 */
import luxurySwitzerlandImage1 from '../assets/images/travels/travels-luxury-body-boost-caroussel-switzerland-image-2.webp';
import luxurySwitzerlandImage2 from '../assets/images/travels/travels-luxury-body-boost-caroussel-switzerland-image-1.webp';
import luxurySwitzerlandImage3 from '../assets/images/travels/travels-luxury-body-boost-caroussel-switzerland-image-3.webp';
import luxurySwitzerlandImage4 from '../assets/images/travels/travels-luxury-body-boost-caroussel-switzerland-image-4.webp';
import luxurySwitzerlandImage5 from '../assets/images/travels/travels-luxury-body-boost-caroussel-switzerland-image-5.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - COURCHEVEL
 * --------------------------------------
 */
import wonderlandCourchevelImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-1.webp';
import wonderlandCourchevelImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-2.webp';
import wonderlandCourchevelImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-3.webp';
import wonderlandCourchevelImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-4.webp';
import wonderlandCourchevelImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-5.webp';
import wonderlandCourchevelImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-6.webp';
import wonderlandCourchevelImage7 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-7.webp';
import wonderlandCourchevelImage8 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-8.webp';
import wonderlandCourchevelImage9 from '../assets/images/travels/travels-white-wonderland-caroussel-courchevel-image-9.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - MEGEVE
 * --------------------------------------
 */
import wonderlandMegeveImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-1.webp';
import wonderlandMegeveImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-2.webp';
import wonderlandMegeveImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-3.webp';
import wonderlandMegeveImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-4.webp';
import wonderlandMegeveImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-5.webp';
import wonderlandMegeveImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-6.webp';
import wonderlandMegeveImage7 from '../assets/images/travels/travels-white-wonderland-caroussel-megeve-image-7.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - SAINT MORITZ
 * --------------------------------------
 */
import wonderlandSaintMoritzImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-1.webp';
import wonderlandSaintMoritzImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-2.webp';
import wonderlandSaintMoritzImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-3.webp';
import wonderlandSaintMoritzImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-4.webp';
import wonderlandSaintMoritzImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-5.webp';
import wonderlandSaintMoritzImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-6.webp';
import wonderlandSaintMoritzImage7 from '../assets/images/travels/travels-white-wonderland-caroussel-st-moritz-image-7.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - VAL D'ISERE
 * --------------------------------------
 */
import wonderlandValIsereImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-1.webp';
import wonderlandValIsereImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-2.webp';
import wonderlandValIsereImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-3.webp';
import wonderlandValIsereImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-4.webp';
import wonderlandValIsereImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-5.webp';
import wonderlandValIsereImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-val-isere-image-6.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - VERBIER
 * --------------------------------------
 */
import wonderlandVerbierImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-1.webp';
import wonderlandVerbierImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-2.webp';
import wonderlandVerbierImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-3.webp';
import wonderlandVerbierImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-4.webp';
import wonderlandVerbierImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-5.webp';
import wonderlandVerbierImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-verbier-image-6.webp';

/**
 * --------------------------------------
 * WHITE WONDERLAND - ZERMAT
 * --------------------------------------
 */
import wonderlandZermatImage1 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-1.webp';
import wonderlandZermatImage2 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-2.webp';
import wonderlandZermatImage3 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-3.webp';
import wonderlandZermatImage4 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-4.webp';
import wonderlandZermatImage5 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-5.webp';
import wonderlandZermatImage6 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-6.webp';
import wonderlandZermatImage7 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-7.webp';
import wonderlandZermatImage8 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-8.webp';
import wonderlandZermatImage9 from '../assets/images/travels/travels-white-wonderland-caroussel-zermatt-image-9.webp';

export const rivieraAixImages = [
  rivieraAixImage1,
  rivieraAixImage2,
  rivieraAixImage3,
  rivieraAixImage4,
  rivieraAixImage5,
  rivieraAixImage6,
  rivieraAixImage7,
  rivieraAixImage8,
  rivieraAixImage9,
  rivieraAixImage10,
];

export const rivieraCannesImages = [
  rivieraCannesImage1,
  rivieraCannesImage2,
  rivieraCannesImage3,
  rivieraCannesImage4,
  rivieraCannesImage5,
  rivieraCannesImage6,
  rivieraCannesImage7,
  rivieraCannesImage8,
];

export const rivieraLuberonImages = [
  rivieraLuberonImage1,
  rivieraLuberonImage2,
  rivieraLuberonImage3,
  rivieraLuberonImage4,
  rivieraLuberonImage5,
  rivieraLuberonImage6,
  rivieraLuberonImage7,
];

export const rivieraMonacoImages = [
  rivieraMonacoImage1,
  rivieraMonacoImage2,
  rivieraMonacoImage3,
  rivieraMonacoImage4,
  rivieraMonacoImage5,
  rivieraMonacoImage6,
  rivieraMonacoImage7,
];

export const rivieraSaintTropezImages = [
  rivieraSaintTropezImage1,
  rivieraSaintTropezImage2,
  rivieraSaintTropezImage3,
  rivieraSaintTropezImage4,
  rivieraSaintTropezImage5,
  rivieraSaintTropezImage6,
  rivieraSaintTropezImage7,
  rivieraSaintTropezImage8,
];

export const rivieraVarImages = [
  rivieraVarImage1,
  rivieraVarImage2,
  rivieraVarImage3,
  rivieraVarImage4,
  rivieraVarImage5,
  rivieraVarImage6,
  rivieraVarImage7,
];

export const luxuryThailandeImages = [
  luxuryThailandeImage1,
  luxuryThailandeImage2,
  luxuryThailandeImage3,
  luxuryThailandeImage4,
  luxuryThailandeImage5,
  luxuryThailandeImage6,
  luxuryThailandeImage7,
  luxuryThailandeImage8,
  luxuryThailandeImage9,
  luxuryThailandeImage10,
  luxuryThailandeImage11,
  luxuryThailandeImage12,
  luxuryThailandeImage13,
  luxuryThailandeImage14,
  luxuryThailandeImage15,
  luxuryThailandeImage16,
  luxuryThailandeImage17,
  luxuryThailandeImage18,
  luxuryThailandeImage19,
  luxuryThailandeImage20,
];

export const luxuryGermanyImages = [
  luxuryGermanyImage1,
  luxuryGermanyImage2,
  luxuryGermanyImage3,
  luxuryGermanyImage4,
  luxuryGermanyImage5,
];

export const luxuryGreeceImages = [
  luxuryGreeceImage1,
  luxuryGreeceImage2,
  luxuryGreeceImage3,
  luxuryGreeceImage4,
  luxuryGreeceImage5,
  luxuryGreeceImage6,
  luxuryGreeceImage7,
  luxuryGreeceImage8,
  luxuryGreeceImage9,
  luxuryGreeceImage10,
  luxuryGreeceImage11,
  luxuryGreeceImage12,
  luxuryGreeceImage13,
  luxuryGreeceImage14,
  luxuryGreeceImage15,
  luxuryGreeceImage16,
  luxuryGreeceImage17,
  luxuryGreeceImage18,
];


export const luxurySpainImages = [
  luxurySpainImage1,
  luxurySpainImage2,
  luxurySpainImage3,
  luxurySpainImage4,
  luxurySpainImage5,
];

export const luxurySwitzerlandImages = [
  luxurySwitzerlandImage1,
  luxurySwitzerlandImage2,
  luxurySwitzerlandImage3,
  luxurySwitzerlandImage4,
  luxurySwitzerlandImage5,
];

export const wonderlandCourchevelImages = [
  wonderlandCourchevelImage1,
  wonderlandCourchevelImage2,
  wonderlandCourchevelImage3,
  wonderlandCourchevelImage4,
  wonderlandCourchevelImage5,
  wonderlandCourchevelImage6,
  wonderlandCourchevelImage7,
  wonderlandCourchevelImage8,
  wonderlandCourchevelImage9,
];

export const wonderlandMegeveImages = [
  wonderlandMegeveImage1,
  wonderlandMegeveImage2,
  wonderlandMegeveImage3,
  wonderlandMegeveImage4,
  wonderlandMegeveImage5,
  wonderlandMegeveImage6,
  wonderlandMegeveImage7,
];

export const wonderlandSaintMoritzImages = [
  wonderlandSaintMoritzImage1,
  wonderlandSaintMoritzImage2,
  wonderlandSaintMoritzImage3,
  wonderlandSaintMoritzImage4,
  wonderlandSaintMoritzImage5,
  wonderlandSaintMoritzImage6,
  wonderlandSaintMoritzImage7,
];

export const wonderlandValIsereImages = [
  wonderlandValIsereImage1,
  wonderlandValIsereImage2,
  wonderlandValIsereImage3,
  wonderlandValIsereImage4,
  wonderlandValIsereImage5,
  wonderlandValIsereImage6,
];

export const wonderlandVerbierImages = [
  wonderlandVerbierImage1,
  wonderlandVerbierImage2,
  wonderlandVerbierImage3,
  wonderlandVerbierImage4,
  wonderlandVerbierImage5,
  wonderlandVerbierImage6,
];

export const wonderlandZermattImages = [
  wonderlandZermatImage1,
  wonderlandZermatImage2,
  wonderlandZermatImage3,
  wonderlandZermatImage4,
  wonderlandZermatImage5,
  wonderlandZermatImage6,
  wonderlandZermatImage7,
  wonderlandZermatImage8,
  wonderlandZermatImage9,
];
