import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { travelDetails } from '../data/travelsDetails';

import PageWithParallax from '../components/PageWithParallax';
import H1 from '../components/title/H1';
import H2 from '../components/title/H2';
import Button from '../components/Button';
import Modal from '../components/Modal';
import ShadowedImage from '../components/ShadowedImage';
import ButtonBack from '../components/ButtonBack';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const TravelDetail = () => {
  const { destination } = useParams();
  const [currentDestination, setCurrentDestination] = useState();
  const pageInfo = travelDetails[destination];

  return (
    <PageWithParallax
      imageTop={pageInfo.imageTop}
      imgBackground={pageInfo.imageParallax}
      paddingTop={pageInfo.paddingTop}
      marginTop={pageInfo.marginTop}
      marginTopMobile={pageInfo.marginTopMobile}
      imageWeight={pageInfo.imageWeight}
    >
      <ButtonBack />

      <H1>{pageInfo.title}</H1>

      <p className="mt-8 max-w-xl">
        {pageInfo.description}
      </p>

      <ul className="my-16 w-full flex flex-col flex-wrap justify-center items-center gap-16 lg:max-w-4xl mt-[5rem]">
        {pageInfo.destinations.map(({ choices }) => (
          choices.map(({ title, tagLink, caroussel }, i) => (
            <li
              key={`${tagLink}-${i}`}
              className="group flex flex-col items-center w-full cursor-pointer"
              onClick={() => setCurrentDestination(choices[i])}
            >
              <H2 className="!pb-0 my-2 text-3xl lg:group-hover:text-gold transition duration-300">
                {title}
              </H2>

              <ShadowedImage
                className="h-48 md:h-64 lg:h-96 w-full rounded-lg lg:group-hover:grayscale transition duration-300"
                src={caroussel[0]}
                alt={title}
              />
            </li>
          ))
        ))}
      </ul>

      <Link className="btn text-xs rounded-full" to="/exclusivities">
        EXCITING EXCLUSIVITIES THAT WAY
      </Link>

      <p className="my-8 mx-auto max-w-lg w-full">
        All of our travels are tailor-made to your needs. We can take care of
        everything, from logistics to accomodation, and everything in between,
        so why not spice your journey up with some experiences only attainable
        through our network?
      </p>

      <Modal className="max-w-8xl px-0 md:px-8" open={!!currentDestination} onClose={() => setCurrentDestination(null)}>
        {currentDestination && (
          <section className="flex flex-col items-center gap-8">
            <H2 className="!p-0">
              {currentDestination.title}
            </H2>

            <p className="text-xl max-w-4xl">
              {currentDestination.description}
            </p>

            <Swiper
              className="h-[40vh] w-full"
              modules={[Navigation, Pagination]}
              grabCursor
              loop
              navigation
              centeredSlides
              slidesPerView={"auto"}
              spaceBetween={30}
              pagination={{
                clickable: true,
                renderBullet: (_, className) => {
                  return '<span class="' + className + ' block w-2 h-2 bg-gold"></span>';
                },
              }}
              onSwiper={(swiper) => {
                setTimeout(() => {
                  try {
                    swiper.slideToLoop(0, 0)
                  } catch (e) { }
                }, 100)
              }}
            >
              {currentDestination.caroussel.map((image, key) => (
                <SwiperSlide className="w-auto" key={image}>
                  <img className="h-full" src={image} alt="An example of destination" />
                </SwiperSlide>
              ))}
            </Swiper>

            <Button>
              <Link to={`/contact?title=${currentDestination.title}&service=${currentDestination.title.replace("&", "%26")}`}>
                GO TO BOOKING
              </Link>
            </Button>

          </section>
        )}
      </Modal>
    </PageWithParallax >
  );
};

export default TravelDetail;
