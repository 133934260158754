import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import PageWithParallax from '../components/PageWithParallax';
import H1 from '../components/title/H1';
import H2 from '../components/title/H2';
import Button from '../components/Button';
import Modal from '../components/Modal';
import ButtonBack from '../components/ButtonBack';

import pageInfo from '../data/services';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Service = () => {
  const [currentService, setCurrentService] = useState();

  return (
    <PageWithParallax
      imageTop={pageInfo.imageTop}
      imgBackground={pageInfo.imageParallax}
      paddingTop={pageInfo.paddingTop}
      marginTop={pageInfo.marginTop}
      marginTopMobile={pageInfo.marginTopMobile}
      imageWeight={pageInfo.imageWeight}
      style={{
        backgroundImage: `radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 70%)`,
      }}
    >
      <ButtonBack />

      <H1>{pageInfo.title}</H1>

      <p className="my-8 max-w-md">{pageInfo.description}</p>

      <Swiper
        className="mt-4 mb-16 lg:my-12 w-full"
        modules={[Navigation, Pagination]}
        grabCursor
        loop
        navigation
        centeredSlides
        slidesPerView="auto"
        spaceBetween={30}
        pagination={{
          clickable: true,
          renderBullet: (_, className) => {
            return (
              '<span class="' + className + ' block w-2 h-2 bg-gold"></span>'
            );
          },
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            try {
              swiper.slideToLoop(0, 0);
            } catch (e) {}
          }, 100);
        }}
      >
        {pageInfo.choices.map(({ title, tagLink, caroussel }, key) => (
          <SwiperSlide className="group pb-12 w-52 lg:w-96" key={key}>
            <Link
              to={tagLink}
              onClick={(e) => {
                e.preventDefault();
                setCurrentService(pageInfo.choices[key]);
              }}
            >
              <H2 className="my-0 text-3xl grow lg:group-hover:text-gold transition duration-300 pb-1">
                {title}
              </H2>
              <div className={`mx-auto h-[1px] bg-gray-500 w-[21%] mb-6`}></div>
              <div
                className="w-52 lg:w-96 h-48 md:h-64 lg:h-96 bg-cover bg-center rounded-lg group-hover:grayscale"
                style={{ backgroundImage: `url("${caroussel[0]}")` }}
              >
                <img
                  className="hidden h-48 md:h-64 lg:h-96 w-full rounded-lg lg:group-hover:grayscale transition duration-300"
                  src={caroussel[0]}
                  alt={title}
                />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      <Modal
        className="max-w-8xl px-0 md:px-8"
        open={!!currentService}
        containerStyle={{
          backgroundImage: `url("${currentService?.background}")`,
        }}
        containerClassName="bg-cover bg-center bg-no-repeat"
        onClose={() => setCurrentService(null)}
      >
        {currentService && (
          <section className="flex flex-col items-center gap-8">
            <H2 className="!p-0">{currentService.title}</H2>

            <p className="text-xl max-w-4xl">{currentService.description}</p>

            <Swiper
              className="h-[40vh] w-full"
              modules={[Navigation, Pagination]}
              grabCursor
              loop
              navigation
              centeredSlides
              slidesPerView={'auto'}
              spaceBetween={30}
              pagination={{
                clickable: true,
                renderBullet: (_, className) => {
                  return (
                    '<span class="' +
                    className +
                    ' block w-2 h-2 bg-gold"></span>'
                  );
                },
              }}
              onSwiper={(swiper) => {
                setTimeout(() => {
                  try {
                    swiper.slideToLoop(0, 0);
                  } catch (e) {}
                }, 200);
              }}
            >
              {currentService.caroussel.map((image, key) => (
                <SwiperSlide className="w-auto" key={image}>
                  <img
                    className="h-full"
                    src={image}
                    alt="Service preview"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <Button>
              <Link
                to={`/contact?title=${
                  currentService.title
                }&service=${currentService.title.replace('&', '%26')}`}
              >
                GO TO BOOKING
              </Link>
            </Button>
          </section>
        )}
      </Modal>
    </PageWithParallax>
  );
};

export default Service;
