import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import './loginKeypad.style.css';

export default function LoginKeypad({
  codeLength = 4,
  initialAnimation = 'visible',
  onChange,
  reset,
  setReset,
  onFullfilled,
  keysProps = {},
  ...props
}) {
  /**
   * Array of each keys, randomly sorted.
   */
  const [keys] = useState(
    [...Array(10).keys()].sort(() => Math.random() - 0.5)
  );

  /**
   * The user code
   */
  const [code, setCode] = useState('');

  const onKeyClick = (key) => {
    const newCode = String(code.length === codeLength ? key : code + key);

    setCode(newCode);

    // Calling onChange each time
    onChange && onChange(newCode);

    // Calling onFinish only when the code is fullfilled
    if (newCode.length === codeLength && onFullfilled) {
      onFullfilled(newCode);
    }
  };

  useEffect(() => {
    if (reset) {
      setCode(''.padEnd(codeLength, '-'));
      onChange && onChange(code);
      setReset(false);
    }
  }, [reset, code, onChange, codeLength, setReset]);

  return (
    <motion.div className="login-keypad" {...props}>
      {keys.map((key) => (
        <motion.button
          {...keysProps}
          key={key}
          className="key"
          onClick={() => onKeyClick(key)}
        >
          {key}
        </motion.button>
      ))}
    </motion.div>
  );
}
