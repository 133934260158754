import Page from '../components/Page';
import Header from '../components/Header';
import backgroundDesktop from '../assets/images/main-page-klavem.png';
import { useMediaQuery } from 'react-responsive';
import background from '../assets/images/dashboard-mobile.png';
import { useState } from 'react';

const Dashboard = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1200px)',
  });

  const [overridenShowMenu, setOverridenShowMenu] = useState(false);

  const toggleMenu = () => { 
    setOverridenShowMenu(!overridenShowMenu);
  };

  return (
    <Page className="flex flex-col items-center h-screen overflow-hidden dashboard">
      <Header useOverridenShowMenu={true} overridenShowMenu={overridenShowMenu} setOverridenShowMenu={setOverridenShowMenu}  />
      {isDesktop ? (
        <>
        <div
          className="fixed top-0 left-0 min-h-full min-w-full bg-center"
          style={{ backgroundImage: `url("${backgroundDesktop}")`, backgroundSize: "cover" }}
        >
          {/* <h1 onClick={toggleMenu}>KLAVEM</h1> */}
        </div>
        </>
      ) : (
        <div
          className="fixed top-0 left-0 min-h-full min-w-full bg-center"
          style={{ backgroundImage: `url("${background}")` }}
        >
          <h1 onClick={toggleMenu}>KLAVEM</h1>
        </div>
      )}
    </Page>
  );
};

export default Dashboard;
