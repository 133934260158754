import Header from "./Header";
import Parallax from "./Parallax";

export default function PageWithParallax({
  imageTop,
  imageWeight,
  imgBackground,
  paddingTop,
  marginTop,
  marginTopMobile,
  style,
  children
}) {
  return (
    <>
      <Header />
      <main className="mx-auto max-w-8xl lg:px-0">
        <Parallax
          imageTop={imageTop}
          imageWeight={imageWeight}
          imgBackground={imgBackground}
          paddingTop={paddingTop}
          marginTop={marginTop}
          marginTopMobile={marginTopMobile}
        />

        <section className="py-8 px-4 mx-auto flex flex-col items-center text-center bg-black">
          {children}
        </section>
      </main>
    </>
  )
}
