import { useNavigate } from 'react-router-dom';

import Button from './Button';

import chevronLeft from '../assets/icons/chevron-left.png';

const ButtonBack = ({ url }) => {
  let navigate = useNavigate();

  const onClick = (e) => {
    e.preventDefault();
    navigate(url ?? -1);
  };

  return (
    <Button
      className="btn !px-2 fixed bottom-4 left-4 rounded-lg flex items-center gap-4 bg-black/70 z-40"
      onClick={onClick}
    >
      <img src={chevronLeft} alt="Go to previous page" width={12} height={12} />

      <p className="text-xs font-normal">BACK</p>
    </Button>
  );
};

export default ButtonBack;
