import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';

/**
 * Returns the logged in user token
 *
 * @returns String
 */
const getToken = () => JSON.parse(localStorage.getItem('currentUser'));

/**
 * Returns the current user from local storage
 */
const getUserFromStore = () => {
  const tokenStore = getToken();

  if (!tokenStore?.token) {
    return null;
  }

  let user = jwt_decode(tokenStore.token);

  return user;
};

/**
 * Store the given user in the local storage
 */
const storeUser = (user) => {
  return localStorage.setItem('currentUser', JSON.stringify(user));
};

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, saveCurrentUser] = useState(getUserFromStore());

  const value = {
    currentUser,
    token: getToken(),
    setCurrentUser: (user) => {
      storeUser(user);
      saveCurrentUser(getUserFromStore());
    },
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
