import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../context/user.context';

import useGyroscope from '../hooks/useGyroscope';

const Greeting = () => {
  const { requestPermission } = useGyroscope();
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const onJourneyBegin = async () => {
    await requestPermission();
    navigate('/home');
  };

  return (
    <main className="portrait greeting-page">
      <p
        style={{
          letterSpacing: '2px',
          position: 'relative',
        }}
      >
        Dear{' '}
        <span>
          {currentUser?.firstname &&
            currentUser?.firstname.charAt(0).toUpperCase() +
              currentUser.firstname.slice(1)}
        </span>
        ,
      </p>

      <p
        style={{
          color: '#595959',
          letterSpacing: '2px',
          lineHeight: '3rem',
          position: 'relative',
          padding: '0 6%',
        }}
      >
        I am an explorer. A trend setter. Collecting hidden exclusivities,
        Designing journeys for sensational memories.
        {/*Let me grant you access to what you cannot possess,*/}
      </p>
      <p
        style={{
          fontSize: '1.75rem',
          letterSpacing: '2px',
          lineHeight: '3rem',
          position: 'relative',
          padding: '0 6%',
          fontFamily: 'Mordernline',
        }}
      >
        Klavem.
      </p>
      <button onClick={onJourneyBegin} style={{ position: 'relative' }}>
        LET THE JOURNEY BEGIN
      </button>
    </main>
  );
};

export default Greeting;
