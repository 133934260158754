import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PageWithParallax from '../components/PageWithParallax';
import H1 from '../components/title/H1';
import ButtonBack from '../components/ButtonBack';

import pageInfo from '../data/exclusivities';

import parallaxVideo from '../assets/images/exclusivities/exclusivities-parallax-background.png';

import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const Exclusivities = () => {
  return (
    <PageWithParallax
      imageTop={pageInfo.imageTop}
      imgBackground={parallaxVideo}
      paddingTop={50}
      imageWeight={pageInfo.imageWeight}
      marginTop={pageInfo.marginTop}
      marginTopMobile={pageInfo.marginTopMobile}
    >
      <ButtonBack />

      <H1 className="pb-0">
        {pageInfo.title}
      </H1>

      <p className="mt-8 max-w-lg">
        {pageInfo.description}
      </p>

      <Swiper
        className="mt-4 mb-16 lg:my-12 w-full"
        modules={[Navigation, Pagination]}
        grabCursor
        loop
        navigation
        centeredSlides
        slidesPerView="auto"
        spaceBetween={30}
        pagination={{
          clickable: true,
          renderBullet: (_, className) => {
            return '<span class="' + className + ' block w-2 h-2 bg-gold"></span>';
          },
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            try {
              swiper.slideToLoop(0, 0)
            } catch (e) { }
          }, 100)
        }}
      >
        {pageInfo.categories.map(({ title, link, thumbnail }, key) => (
          <SwiperSlide className="group pb-12 w-auto" key={key}>
            <Link to={`/exclusivities/${link}/details`}>
              <img className="mb-8 h-72 lg:h-72" src={thumbnail} alt={title} />
              <h2 className={`pb-2 text-3xl text-center text-xl lg:group-hover:text-gold transition duration-300`}>
                {title}
              </h2>
              <div className={`mx-auto h-[1px] bg-gray-500 w-[28%]`}></div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </PageWithParallax>
  );
};

export default Exclusivities;
