/**
 * Images carousel services chalet
 */
import chaletImage1 from '../assets/images/services/service-chalet-image-1.webp';
import chaletImage2 from '../assets/images/services/service-chalet-image-2.webp';
import chaletImage3 from '../assets/images/services/service-chalet-image-3.webp';
import chaletImage4 from '../assets/images/services/service-chalet-image-4.webp';
import chaletImage5 from '../assets/images/services/service-chalet-image-5.webp';
import chaletImage6 from '../assets/images/services/service-chalet-image-6.webp';
import chaletImage7 from '../assets/images/services/service-chalet-image-7.webp';
import chaletImage8 from '../assets/images/services/service-chalet-image-8.webp';

/**
 * Images carousel services helicopter
 */
import helicopterImage1 from '../assets/images/services/service-helicopter-image-1.webp';
import helicopterImage2 from '../assets/images/services/service-helicopter-image-2.webp';
import helicopterImage3 from '../assets/images/services/service-helicopter-image-3.webp';
import helicopterImage4 from '../assets/images/services/service-helicopter-image-4.webp';
import helicopterImage5 from '../assets/images/services/service-helicopter-image-5.webp';
import helicopterImage6 from '../assets/images/services/service-helicopter-image-6.webp';
import helicopterImage7 from '../assets/images/services/service-helicopter-image-7.webp';

/**
 * Images carousel services hotel
 */
import hotelImage1 from '../assets/images/services/service-hotel-image-1.webp';
import hotelImage2 from '../assets/images/services/service-hotel-image-2.webp';
import hotelImage3 from '../assets/images/services/service-hotel-image-3.webp';
import hotelImage4 from '../assets/images/services/service-hotel-image-4.webp';
import hotelImage5 from '../assets/images/services/service-hotel-image-5.webp';
import hotelImage6 from '../assets/images/services/service-hotel-image-6.webp';
import hotelImage7 from '../assets/images/services/service-hotel-image-7.webp';
import hotelImage8 from '../assets/images/services/service-hotel-image-8.webp';
import hotelImage9 from '../assets/images/services/service-hotel-image-9.webp';

/**
 * Images carousel services chauffeur
 */
import chauffeurImage1 from '../assets/images/services/services-driver-image-1.webp';
import chauffeurImage2 from '../assets/images/services/services-driver-image-2.webp';
import chauffeurImage3 from '../assets/images/services/services-driver-image-3.webp';
import chauffeurImage4 from '../assets/images/services/services-driver-image-4.webp';

/**
 * Images carousel services jet
 */
import jetImage1 from '../assets/images/services/services-jet-image-1.webp';
import jetImage2 from '../assets/images/services/services-jet-image-2.webp';
import jetImage3 from '../assets/images/services/services-jet-image-3.webp';
import jetImage4 from '../assets/images/services/services-jet-image-4.webp';
import jetImage5 from '../assets/images/services/services-jet-image-5.webp';
import jetImage6 from '../assets/images/services/services-jet-image-6.webp';

/**
 * Images carousel services villa
 */
import villaImage1 from '../assets/images/services/services-villa-image-1.webp';
import villaImage2 from '../assets/images/services/services-villa-image-2.webp';
import villaImage3 from '../assets/images/services/services-villa-image-3.webp';
import villaImage4 from '../assets/images/services/services-villa-image-4.webp';
import villaImage5 from '../assets/images/services/services-villa-image-5.webp';
import villaImage6 from '../assets/images/services/services-villa-image-6.webp';
import villaImage7 from '../assets/images/services/services-villa-image-7.webp';
import villaImage8 from '../assets/images/services/services-villa-image-8.webp';
import villaImage9 from '../assets/images/services/services-villa-image-9.webp';
import villaImage10 from '../assets/images/services/services-villa-image-10.webp';

/**
 * Images carousel services vintage & supercar
 */
import supercarImage1 from '../assets/images/services/services-supercar-image-1.webp';
import supercarImage2 from '../assets/images/services/services-supercar-image-2.webp';
import supercarImage3 from '../assets/images/services/services-supercar-image-3.webp';
import supercarImage4 from '../assets/images/services/services-supercar-image-4.webp';
import supercarImage5 from '../assets/images/services/services-supercar-image-5.webp';
import supercarImage6 from '../assets/images/services/services-supercar-image-6.webp';
import supercarImage7 from '../assets/images/services/services-supercar-image-7.webp';
import supercarImage8 from '../assets/images/services/services-supercar-image-8.webp';
import supercarImage9 from '../assets/images/services/services-supercar-image-9.webp';
import supercarImage10 from '../assets/images/services/services-supercar-image-10.webp';
import supercarImage11 from '../assets/images/services/services-supercar-image-11.webp';
import supercarImage12 from '../assets/images/services/services-supercar-image-12.webp';

/**
 * Images carousel services yatch
 */
import yatchImage1 from '../assets/images/services/services-yatch-image-1.webp';
import yatchImage2 from '../assets/images/services/services-yatch-image-2.webp';
import yatchImage3 from '../assets/images/services/services-yatch-image-3.webp';
import yatchImage4 from '../assets/images/services/services-yatch-image-4.webp';
import yatchImage5 from '../assets/images/services/services-yatch-image-5.webp';
import yatchImage6 from '../assets/images/services/services-yatch-image-6.webp';
import yatchImage7 from '../assets/images/services/services-yatch-image-7.webp';
import yatchImage8 from '../assets/images/services/services-yatch-image-8.webp';
import yatchImage9 from '../assets/images/services/services-yatch-image-9.webp';
import yatchImage10 from '../assets/images/services/services-yatch-image-10.webp';

export const chaletCarouselImages = [
  chaletImage1,
  chaletImage2,
  chaletImage3,
  chaletImage4,
  chaletImage5,
  chaletImage6,
  chaletImage7,
  chaletImage8,
];
export const helicopterCarouselImages = [
  helicopterImage1,
  helicopterImage2,
  helicopterImage3,
  helicopterImage4,
  helicopterImage5,
  helicopterImage6,
  helicopterImage7,
];
export const hotelCarouselImages = [
  hotelImage1,
  hotelImage2,
  hotelImage3,
  hotelImage4,
  hotelImage5,
  hotelImage6,
  hotelImage7,
  hotelImage8,
  hotelImage9,
];
export const chauffeurCarouselImages = [
  chauffeurImage1,
  chauffeurImage2,
  chauffeurImage3,
  chauffeurImage4,
];
export const jetCarouselImages = [
  jetImage1,
  jetImage2,
  jetImage3,
  jetImage4,
  jetImage5,
  jetImage6,
];
export const villaCarouselImages = [
  villaImage1,
  villaImage2,
  villaImage3,
  villaImage4,
  villaImage5,
  villaImage6,
  villaImage7,
  villaImage8,
  villaImage9,
  villaImage10,
];
export const supercarCarouselImages = [
  supercarImage1,
  supercarImage2,
  supercarImage3,
  supercarImage4,
  supercarImage5,
  supercarImage6,
  supercarImage7,
  supercarImage8,
  supercarImage9,
  supercarImage10,
  supercarImage11,
  supercarImage12,
];
export const yatchCarouselImages = [
  yatchImage1,
  yatchImage2,
  yatchImage3,
  yatchImage4,
  yatchImage5,
  yatchImage6,
  yatchImage7,
  yatchImage8,
  yatchImage9,
  yatchImage10,
];
