import { Link } from 'react-router-dom';

import PageWithHeader from '../components/PageWithHeader';
import H1 from '../components/title/H1';
import Button from '../components/Button';

import bookImage from '../assets/images/book.png';

const Book = () => {
  return (
    <PageWithHeader
      className="flex pt-[80px] flex-col items-center justify-center text-center"
      style={{
        backgroundImage: `radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 70%)`,
      }}
    >
      <H1 withSeparator={false}>An amazing souvenir</H1>

      <p className="my-16 mx-auto mb-16 max-w-xl  w-full justify-text">
      We have at heart that each member setting off on a KLAVEM experience receives
       a tailor-made souvenir that will be delivered to their home. 
       We are confident that this unique keepsake
        will leave a lasting impression, providing you 
        with the opportunity to amaze your friends and relatives.
         Prepare to immerse yourself once again in the emotional journey of a lifetime.
      </p>

      <img
        className="mb-16 mx-auto w-full w-xl"
        src={bookImage}
        alt="An old book illustration"
      />

      <Link to="/contact?title=YOUR ULTIMATE TRAVEL DIARY&service=Travel Diary">
        <Button>LET'S TALK ADVENTURES</Button>
      </Link>
    </PageWithHeader>
  );
};

export default Book;
