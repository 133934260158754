import Separator from './Separator';

export default function Button({
  variant = 'primary',
  className = '',
  onClick = () => {},
  children,
}) {
  return variant === 'secondary' ? (
    <button
      type="button"
      className={`
        font-montserrat
        text-sm
        cursor-pointer
        text-gray-400
        lg:hover:text-gold
        transition-all duration-300
        ${className}
      `}
      onClick={onClick}
    >
      <span>{children}</span>

      <Separator className="!mt-2" />
    </button>
  ) : (
    <button
      type="button"
      className={`
        font-montserrat
        py-2 px-8 text-sm max-w-fit rounded-full cursor-pointer
        lg:hover:bg-gold lg:hover:text-black 
        lg:group-hover:bg-gold lg:group-hover:text-black 
        transition-all duration-300
        ${className}
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
