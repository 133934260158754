import Separator from '../Separator'

export default function H1({ withSeparator = false, className = "", children }) {
  return (
    <h1 className={`pb-4 text-4xl lg:text-5xl text-center uppercase font-canto-roman ${className}`}>
      {children}

      {withSeparator && (
        <Separator />
      )}
    </h1>
  )
}
