import MenuMain from './Menu';

const Header = ({useOverridenShowMenu, overridenShowMenu, setOverridenShowMenu}) => {
  return (
    <header className="sticky top-0 left-0 w-full flex items-center justify-between lg:border-b border-white/10 z-50">
      <MenuMain useOverridenShowMenu={useOverridenShowMenu} overridenShowMenu={overridenShowMenu} setOverridenShowMenu={setOverridenShowMenu} />
    </header>
  );
};

export default Header;
