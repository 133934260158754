export default function ShadowedImage({ src, alt = "", className = "" }) {
  return (
    <div
      className={`shadowed-container bg-center bg-no-repeat bg-cover w-full ${className}`}
      style={{ backgroundImage: `url("${src}")` }}
    >
      <img src={src} className="hidden" alt={alt} />
    </div>
  )
}
