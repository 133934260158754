import { Link, useParams } from 'react-router-dom';

import exclusivities from '../data/exclusivities';

import PageWithHeader from "../components/PageWithHeader";
import ButtonBack from "../components/ButtonBack";
import H1 from "../components/title/H1";
import H2 from "../components/title/H2";
import ShadowedImage from "../components/ShadowedImage";
import Button from '../components/Button';

const ExclusivitiesDetail = () => {
  const { exclusivityName } = useParams();

  const exclusivity = exclusivities.categories.find(
    ({ link }) => link === exclusivityName
  );

  return (
    <PageWithHeader className="pt-[40px]">
      <ButtonBack />

      <H1>{exclusivity.title}</H1>

      <p className="mx-auto mt-2 max-w-lg exclu-desc">
        {exclusivity.description}
      </p>

      <ul className="mt-24 mb-16 w-full flex flex-col flex-wrap justify-center items-center sm:items-stretch gap-32 lg:max-w-4xl">
        {exclusivity.details.map(({ title, description, image }, key) => (
          <li
            key={key}
            className="flex flex-col items-center w-full"
          >
            <H2 className="grow !p-0 !text-2xl lg:!text-3xl">
              {title}
            </H2>

            <ShadowedImage className="my-8 h-48 md:h-64 lg:h-96 w-full rounded-lg" src={image} alt={`Picture of ${title}`} />

            <p className="grow exclu-desc">
              {description}
            </p>

            <Link className="mt-8" to={`/contact`}>
              <Button>
                Make a request
              </Button>
            </Link>
          </li>
        ))}
      </ul>

      <p className="text-4xl text-gray-400 text-center">And many more...</p>
    </PageWithHeader >
  );
};

export default ExclusivitiesDetail;
