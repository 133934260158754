import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import H2 from '../components/title/H2';
import Button from '../components/Button';
import ButtonBack from '../components/ButtonBack';
import PageWithHeader from '../components/PageWithHeader';
import { Swiper, SwiperSlide } from 'swiper/react';
import travels from '../data/travels';

const Travel = () => {
  const [currentService, setCurrentService] = useState();
  return (
    <PageWithHeader className="lg:!px-0">
      <ul className="my-16 w-full flex flex-col lg:flex-row items-center gap-8">
      <Swiper
        className="mt-4 mb-16 lg:my-12 w-full"
        modules={[Navigation, Pagination]}
        grabCursor
        loop
        navigation
        centeredSlides
        slidesPerView="auto"
        spaceBetween={30}
        pagination={{
          clickable: true,
          renderBullet: (_, className) => {
            return (
              '<span class="' + className + ' block w-2 h-2 bg-gold"></span>'
            );
          },
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            try {
              swiper.slideToLoop(0, 0);
            } catch (e) {}
          }, 100);
        }}
      >
        {travels.map(({ name, thumbnail, description, link, linkText }, key) => (
          <SwiperSlide className="group pb-12 slide-travel w-52 lg:w-96" key={key}>
             <li className="my-8 flex w-full travel-box">
              <Link
                to={link}
                className="w-full group flex flex-col items-center gap-0 lg:gap-4"
              >
                <H2 className="!pb-0 grow lg:group-hover:text-gold uppercase transition duration-300 text-2xl">
                  {name}
                </H2>

                <p className="text-gray-400 text-center lg:max-w-[230px] lg:group-hover:text-gold transition duration-300">
                  {description}
                </p>

                <img
                  className="grow lg:mt-[0px] mt-[25px] lg:mb-[0px] mb-[10px] rounded-lg max-w-xl w-full h-48 lg:group-hover:grayscale transition duration-300"
                  src={thumbnail}
                  alt={name}
                />

                <Button className="btn py-2 px-8 text-sm rounded-full uppercase">
                  Dive me in
                </Button>
              </Link>
            </li>
          </SwiperSlide>
        ))}
      </Swiper>
      </ul>

      <div className="text-center seeking-button">
        <Link to="/contact">
          <Button variant="secondary">
            SEEKING SOMETHING ELSE? WE'RE ALL EARS
          </Button>
        </Link>
      </div>

      <ButtonBack />
    </PageWithHeader>
  );
};

export default Travel;
