import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const routesTitles = {
  '/travels': 'Tailor-Made Travels',
  '/travels/european-odyssey': 'European Odyssey',
  '/travels/luxury-body-boost': 'Luxury Body Boost',
  '/travels/white-wonderland': 'White Wonderland',

  '/exclusivities': 'Latest Exclusivities',
  '/exclusivities/gastronomy': 'Gastronomy',
  '/exclusivities/motor-sports': 'Sports',
  '/exclusivities/art-and-fashion': 'Art & Fashion',
  '/exclusivities/fashion': 'Fashion',
  '/exclusivities/out-of-this-world': 'Out Of This World',
  '/exclusivities/wines-and-spirits': 'Wines & Spirits',

  '/services': 'Concierge Services',
  '/services/private-jet': 'Private Jet',
  '/services/helicopter': 'Helicopter',
  '/services/yacht': 'Yacht',
  '/services/vintage-and-supercar': 'Vintage and Supercar',
  '/services/private-driver': 'Private driver',
  '/services/chalets': 'Chalets',
  '/services/private-villas': 'Private Villas',
  '/services/hotel-bookings': 'Hotel Bookings',

  '/about-us': 'About Us',
  '/book': 'Your Ultimate Travel Diary',
  '/contact': 'Get in touch',
};

const containerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: 'black',
  zIndex: 100,
};

const titleStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Montserrat',
  zIndex: 110,

  /* Disable text selection on title */
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
};

const containerVariants = {
  initial: {
    opacity: 1,
  },
  show: {
    opacity: 1,
    display: 'block',
    transition: {
      duration: 1,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 1,
      delay: 4,
    },
    transitionEnd: {
      display: 'none',
    },
  },
  'hide-fast': {
    opacity: 0,
    transition: {
      duration: 1,
      delay: 1,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const outletVariants = {
  hide: { opacity: 0, transition: { duration: 1 } },
  show: { opacity: 1 },
};

const TransitionLayout = () => {
  const { pathname } = useLocation();
  const title = routesTitles[pathname] ?? '';

  return (
    <>
      {title && (
        <motion.header
          style={titleStyle}
          initial="hidden"
          variants={{
            hidden: {
              display: 'flex',
            },
            visible: {
              display: 'none',
              transition: {
                delayChildren: 0.1,
                staggerChildren: 0.05,
                when: 'afterChildren',
              },
            },
          }}
          animate="visible"
        >
          <h1>
            {Array.from(title).map((letter, i) => (
              <motion.span
                className="text-4xl"
                key={i}
                variants={{
                  hidden: { opacity: 0 },
                  visible: {
                    opacity: [0, 1, 1, 0],
                    transition: { times: [0, 0.2, 0.8, 1], duration: 3 },
                  },
                }}
              >
                {letter}
              </motion.span>
            ))}
          </h1>
        </motion.header>
      )}

      <motion.div
        style={containerStyle}
        variants={containerVariants}
        initial="initial"
        animate={title ? 'hide' : 'hide-fast'}
        exit="show"
        onAnimationStart={() => (document.body.style.overflowY = 'hidden')}
      />

      <motion.div
        className="h-full"
        variants={outletVariants}
        initial="hide"
        animate="show"
        exit="hide"
        onAnimationComplete={() => {
          setTimeout(() => {
            document.body.style.overflowY = 'auto';
            window.scrollTo(0, 0);
          }, 1000);
        }}
      >
        <Outlet />
      </motion.div>
    </>
  );
};

export default TransitionLayout;
