import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { contactRequest } from '../services/klavem-api';
import PageWithHeader from '../components/PageWithHeader';
import H1 from '../components/title/H1';
import Button from '../components/Button';

import { ReactComponent as ArrowRightIcon } from '../assets/icons/arrow-right.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/icons/whatsapp.svg';
import InstagramLogo from '../assets/icons/insta.png';

const Contact = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nationalPhoneNumber, setNationalPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      subject: searchParams.get('title') + ' - ' + searchParams.get('service'),
      message: message,
      nationalPhoneNumber: nationalPhoneNumber,
      email: email,
    };

    try {
      const response = await contactRequest(data);

      if (response.error) {
        throw new Error(response.error);
      }

      await Swal.fire({
        title: 'Great',
        text: 'Message Sent !',
        icon: 'success',
        confirmButtonText: 'Go back to home',
        background: '#000',
        color: '#FFF',
        width: '30vw',
      });

      navigate('/');
    } catch (e) {
      console.error(e);

      Swal.fire({
        title: 'Something went wrong...',
        text: e.message,
        icon: 'error',
        background: '#000',
        color: '#FFF',
      });
    }
  };

  return (
    <>
      <PageWithHeader
        className="flex flex-col justify-center text-center pt-[40px]"
        style={{
          backgroundImage: `radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 70%)`,
        }}
      >
        <H1>Get in Touch</H1>

        <p className="mx-auto text-xl max-w-2xl">
          Reach us with any enquiry, request or curiosity. One of the co-founder
          will take care of your message within 24h.
        </p>

        <form
          className="mt-8 flex flex-col gap-16 text-left"
          action=""
          method="post"
          onSubmit={handleSubmit}
        >
          <section className="flex flex-col gap-4">
            <label htmlFor="message">Your message *</label>

            <textarea
              className="p-4 rounded-lg bg-white/10"
              name="message"
              id="message"
              cols="50"
              rows="3"
              placeholder="Let's work together, tell us about your request"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </section>

          <section className="flex flex-col gap-4">
            <label htmlFor="phone">Phone number *</label>

            <input
              className="p-4 rounded-lg bg-white/10"
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone Number"
              value={nationalPhoneNumber}
              onChange={(e) => setNationalPhoneNumber(e.target.value)}
              required
            />
          </section>

          <section className="flex flex-col gap-4">
            <label htmlFor="email">Email address *</label>

            <input
              className="p-4 rounded-lg bg-white/10"
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <p className="flex justify-end text-sm">(*) Required fields</p>
          </section>

          <section className="flex justify-end">
            <Button className="!px-4 flex items-center gap-4 text-lg">
              <span>Submit</span>
              <ArrowRightIcon width={20} height={20} />
            </Button>
          </section>
        </form>

        <div className="flex md:flex-row mt-12">
          <a
            className="lg:fixed lg:w-[fit-content] w-[50%] bottom-4 left-4 flex
                       flex-col gap-2 align-center justify-center"
            href="https://instagram.com/klavem_club?igshid=YmMyMTA2M2Y="
            target="_blank"
          >
            <img
              className="instagram"
              src={InstagramLogo}
              alt="Instragram Logo"
            />
            <p className="text-md">
              Discover our <br /> Instragram universe
            </p>
          </a>

          <a
            className="lg:fixed lg:w-[fit-content] w-[50%] bottom-4 right-4 flex
                       flex-col gap-2 align-center justify-center"
            href="https://wa.me/+33676087843"
            target="_blank"
          >
            <WhatsAppIcon className="mx-auto" width={26} height={26} />
            <p className="text-md">
              Reach us on <br /> WhatsApp
            </p>
          </a>
        </div>
      </PageWithHeader>
    </>
  );
};

export default Contact;
