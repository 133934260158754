import frenchRivieraThumbnail from '../assets/images/travels/travels-french-riviera-gateway-thumbnail.jpg';
import luxuryBodyBoostThumbnail from '../assets/images/travels/travels-luxury-boost-thumbnail.jpg';
import whiteWonderlandThumbnail from '../assets/images/travels/travels-white-wonderland-thumbnail.jpg';
import africa from '../assets/images/travels/africa_3.jpg';
import lostinparadise from '../assets/images/travels/Vietnam.png';

const travels = [
  {
    name: 'European Odyssey',
    thumbnail: frenchRivieraThumbnail,
    description: 'Explore the ancient continent in a unique way',
    link: 'european-odyssey',
  },
  {
    name: 'Luxury Body Boost',
    thumbnail: luxuryBodyBoostThumbnail,
    description: 'Top Luxury Wellness Retreats Worldwide',
    link: 'luxury-body-boost',
  },
  {
    name: 'White Wonderland',
    thumbnail: whiteWonderlandThumbnail,
    description: 'Snow holidays right out of crazy fairytales',
    link: 'white-wonderland',
  },
  {
    name: 'Safari Vibes',
    thumbnail: africa,
    description: 'Discover the mother continent, spanning 9 amazing countries',
    link: 'work-in-progress',
  },
  {
    name: 'Lost In paradise',
    thumbnail: lostinparadise,
    description: 'Explore the lost worlds\' most pristine paradises',
    link: 'work-in-progress',
  },
];

export default travels;
