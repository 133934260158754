import { useMediaQuery } from 'react-responsive';

const SectionParallax = ({
  imageTop,
  imageWeight = '80%',
  imgBackground,
  paddingTop = '0',
  marginTop = 0, 
  marginTopMobile = marginTop,
}) => {

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  
  return (
    <>
      <div className="lg:mt-28 fixed h-auto w-2/3 lg:w-1/2 max-w-md top-0 left-1/2 -translate-x-1/2 z-[-1]">
        {imgBackground.match(/\.mp4/g) ? (
          <video autoPlay muted loop playsInline>
            <source src={imgBackground} type="video/mp4" />
          </video>
        ) : (
          <img
           
            src={imgBackground}
            className={`mx-auto w-[${imageWeight}]`}
            alt="Category illustration"
          />
        )}
      </div>

      <div
        className="mt-16 lg:mt-0 relative"
        style={{
          background: 'linear-gradient(0deg, rgba(0,0,0,1) 27%, rgba(0,0,0,0) 87%)',
          paddingTop: paddingTop,
        }}
      >
        <img style={{           
              marginTop: isDesktop ? `${marginTop}vh` : `${marginTopMobile}vh`,
            }} src={imageTop.nameFile} alt={imageTop.altText} />
      </div>
    </>
  );
};

export default SectionParallax;
