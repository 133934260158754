import { useState } from 'react';

import PageWithHeader from '../components/PageWithHeader';
import H2 from '../components/title/H2';
import Separator from '../components/Separator';

import teamImg from '../assets/images/team.jpg';

import amanLogo from '../assets/images/partners/aman.png';
import belmondLogo from '../assets/images/partners/belmond.png';
import cremblLogo from '../assets/images/partners/crembl.png';
import dmaLogo from '../assets/images/partners/dma.png';
import lesAirellesLogo from '../assets/images/partners/les-airelles.png';
import oetkerLogo from '../assets/images/partners/oetker-collection.png';
import ultimaLogo from '../assets/images/partners/ultima.png';

const partners = [
  {
    logo: amanLogo,
    url: 'https://www.aman.com',
  },
  {
    logo: belmondLogo,
    url: 'https://www.belmond.com',
  },
  {
    logo: cremblLogo,
    url: 'https://www.club-residents-etrangers-monaco.com',
  },
  {
    logo: dmaLogo,
    url: 'https://dmayachting.com',
  },
  {
    logo: lesAirellesLogo,
    url: 'https://airelles.com',
  },
  {
    logo: oetkerLogo,
    url: 'https://www.oetkercollection.com',
  },
  {
    logo: ultimaLogo,
    url: 'https://www.ultimacollection.com',
  },
];

const ReadMoreButton = ({ onClick }) => (
  <p>
    <button className="btn text-sm py-2 px-4" onClick={onClick}>
      Read more...
    </button>
  </p>
);

const AboutUs = () => {
  const [showThisIsWhoWeAre, setShowThisIsWhoWeAre] = useState(false);
  const [showThisIsWhatWeDo, setShowThisIsWhatWeDo] = useState(false);

  return (
    <PageWithHeader
      className="text-center"
      style={{
        backgroundImage: `radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 70%)`,
      }}
    >
      <section className="mx-auto my-16 flex flex-col gap-8 text-base lg:text-lg">
        <H2 className="!pb-0 uppercase font-canto-roman">This is who we are</H2>

        <div
          className={`rounded-xl h-[350px] sm:h-[450px] md:h-[550px] lg:h-[650px] bg-center bg-no-repeat bg-cover w-full`}
          style={{ backgroundImage: `url("${teamImg}")` }}
        >
          <img src={teamImg} className="hidden" alt="Solene with Sebastien" />
        </div>

        <div className="mx-auto max-w-3xl flex flex-col gap-8">
          <p className="justifytext">
            We grew up as sibling in Aix-en-Provence and now call Monaco, London
            & Paris home.
        
            But the truth is, we are always on the road, hunting for the latest
            travel hot spots, testing them for KLAVEM members & curating
            exclusive experiences only available thanks to our networks.
          </p>

          {showThisIsWhoWeAre ? (
            <>
              <p className="justifytext">
                Solène often travels between Monaco, London, the French Alps &
                Provence, seeking to uncover the best hidden gastronomic
                restaurants & vineyards, the latest party hot spots and new
                incredible experiences in regions where « the place to be »
                changes (oh) so quicky.
           
                Sébastien makes the most of living on the American continent.
                Always a suitcase in hand, he seeks out hidden gems so that you
                don’t have to in many places like the NYC, Miami, Caribbean,
                Mexico or Hawaii. Let him grant you access to amazing VIP
                experiences, ranging from meeting sports celebrities to extreme
                adventures in the wilderness and everything in between.
              </p>
            </>
          ) : (
            <ReadMoreButton onClick={() => setShowThisIsWhoWeAre(true)} />
          )}
        </div>
      </section>

      <Separator />

      <section className="mx-auto my-16 flex flex-col gap-8 text-base lg:text-lg">
        <H2 className="!pb-0 uppercase">This is what we do</H2>

        <div className="mx-auto max-w-3xl flex flex-col gap-8 justifytext">
          <p className="justifytext">
            <strong>KLAVEM</strong> is a Travel Club offering a thrilling way to
            discover destinations: through experiences usually unattainable,
            obtained solely thanks to our network. We are always on the road
            curating the hottest travel spot and hunting for new exclusivities
            so that you don’t have to.
      
            May it be for a tailor-made holidays, corporate travels, weekly
            travel logistics or a day of adventure, we always find a way to make
            your journeys special and seamless.
          </p>

          {showThisIsWhatWeDo ? (
            <>
              
                Even when you might be looking for a gift, a special experience
                for a concert, a sports events or the perfect villa for your
                holidays, whatever it might be, we are a short call away.
              

             
                Our goal? To create unforgettable memories for a selected few,
                ticking off your wildest bucket list items and designing
                experiences you would have never dreamt of.
             

             
                As our brother-sister duo takes care of everything and takes
                pride in its responsiveness, membership is limited.
              
            </>
          ) : (
            <ReadMoreButton onClick={() => setShowThisIsWhatWeDo(true)} />
          )}
        </div>
      </section>

      <Separator />

      <section className="mx-auto my-16 flex flex-col gap-8 text-base lg:text-lg">
        <H2 className="!pb-0 uppercase">This is who we work with</H2>

        <div className="mx-auto max-w-3xl flex flex-col gap-8">
          <p>
            We carefully select our partners & get to know them personally to
            provide you seamlessly top-notch service. Every time.
          </p>
        </div>

        <ul className="flex flex-col lg:flex-row lg:gap-16 lg:flex-wrap items-center justify-center">
          {partners.map(({ logo, url }) => (
            <li className="max-w-[250px]" key={url}>
              <a href={url} target="_blank" rel="noreferrer">
                <img src={logo} alt={`Visit ${url}`} />
              </a>
            </li>
          ))}
        </ul>

        <p className="text-4xl text-gray-400">And many more...</p>
      </section>
    </PageWithHeader>
  );
};

export default AboutUs;
