import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useAnimationControls } from 'framer-motion';
import Header from '../components/Header';
import { UserContext } from '../context/user.context';
import { login } from '../services/klavem-api';

import LoginKeypad from '../components/login-keypad/LoginKeypad.component';

import angelImage from '../assets/images/Sacred-Tree-sans-fond.webp';


const ACCESS_CODE_LENGTH = 4;

const keypadLabelVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const keypadVariants = {
  hidden: {
    transition: { staggerChildren: 0.1, staggerDirection: -1 },
  },
  visible: {
    transition: { staggerChildren: 0.1 },
  },
};

const keypadKeysVariants = {
  hidden: {
    opacity: 0,
    rotateY: 180,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  visible: {
    opacity: 1,
    rotateY: 0,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
};

const accessCodeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  error: {
    rotateZ: [-5, 0, 5, 0],
    color: 'red',
    transition: {
      duration: 0.1,
      repeat: 4,
    },
  },
  success: {
    color: 'white',
  },
};

const Login = () => {
  const navigate = useNavigate();

  const showAccessCodeControls = useAnimationControls();
  const keypadLabelControls = useAnimationControls();
  const keypadControls = useAnimationControls();
  const accessCodeControls = useAnimationControls();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [accessCode, setAccessCode] = useState(
    ''.padEnd(ACCESS_CODE_LENGTH, '-')
  );
  const [reset, setReset] = useState(false);

  const { setCurrentUser } = useContext(UserContext);

  const hideKeypad = async () => {
    await Promise.all([
      keypadLabelControls.start('hidden'),
      accessCodeControls.start('hidden'),
    ]);

    await keypadControls.start('hidden');
  };

  useEffect(() => {
    showAccessCodeControls.start('visible');
  }, [showAccessCodeControls]);

  const onAccessCodeChange = (code) => {
    setAccessCode(code.padEnd(ACCESS_CODE_LENGTH, '-'));
    accessCodeControls.start('success');
  };

  const onAccessCodeFullfilled = async (code) => {
    setIsLoggingIn(true);

    try {
      const data = await login(`k-${code}`);

      if (data.error) {
        throw new Error('Bad access code');
      }

      setCurrentUser(data);

      await hideKeypad();

      navigate('/greeting');
    } catch (e) {
      await accessCodeControls.start('error');
    } finally {
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    if (reset) {
      setAccessCode(''.padEnd(ACCESS_CODE_LENGTH, '-'));
      setReset(false);
    }
  }, [reset, accessCode]);

  return (
    <main className="portrait login-page">
       <Header  />
      <div className="login-section">    
        <div
          className={`keypad-wrapper with-keypad`}
        >
          <motion.p
            className="interaction"
            variants={keypadLabelVariants}
            animate={keypadLabelControls}
          >
            Please enter your code
          </motion.p>

          <LoginKeypad
            codeLength={ACCESS_CODE_LENGTH}
            variants={keypadVariants}
            animate={keypadControls}
            keysProps={{
              variants: keypadKeysVariants,
              whileTap: { scale: 0.9 },
            }}
            onChange={onAccessCodeChange}
            onFullfilled={onAccessCodeFullfilled}
            reset={reset}
            setReset={setReset}
          />

          <motion.p
            className="code"
            variants={accessCodeVariants}
            animate={accessCodeControls}
          >
            {isLoggingIn ? 'Connecting' : `K-${accessCode}`}         
          </motion.p>      
        </div>    
      </div>
    </main>
  );
};

export default Login;
