import { useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export default function Modal({
  className = "",
  containerClassName = "",
  containerStyle = {},
  open = false,
  onClose = () => { },
  children
}) {
  useEffect(() => {

  }, [])

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';

    let keyListener;

    if (open) {
      document.body.addEventListener("keyup", (ev) => {
        ev.code === 'Escape' && onClose && onClose();
      });
    }

    return () => keyListener && document.body.removeEventListener("keyup", keyListener)
  }, [open, onClose])

  return (
    <AnimatePresence>
      {open && (
        <div className="relative" role="dialog" aria-modal="true" onKeyUp={() => console.log("key")}>
          <motion.div
            className="fixed inset-0 h-screen w-screen overflow-y-auto bg-black bg-opacity-90 backdrop-blur-sm z-[99]"
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />

          <motion.div
            className={`py-16 mx-auto fixed inset-0 z-[100] ${containerClassName}`}
            style={containerStyle}
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0, transform: 'scale(0.5)' }}
            animate={{ opacity: 1, transform: 'scale(1)' }}
            exit={{ opacity: 0, transform: 'scale(0.5)' }}
          >
            <div className={`modal-content px-8 mx-auto h-full w-full max-w-5xl overflow-y-auto ${className}`}>
              <button className="btn no-underline px-2 py-0 font-bold absolute top-8 right-8" onClick={onClose}>
                X
              </button>

              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
