const useGyroscope = () => {
  const requestPermission = async () => {
    try {
      if (
        typeof DeviceOrientationEvent !== 'undefined' &&
        typeof DeviceMotionEvent.requestPermission === 'function'
      ) {
        // Handle iOS 13+ devices.
        const state = await DeviceMotionEvent.requestPermission();

        return state === 'granted';
      }
    } catch (e) {
      alert(e.message);
    }

    return true;
  };

  return {
    requestPermission,
  };
};

export default useGyroscope;
