import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';

import menu, { login } from '../data/menu';

import MenuMainButton from './menu-main-button/MenuMainButton.component';

import Logo from '../assets/images/klaven-white.png';
import MobileLogo from '../assets/images/logo-large.png';
import InstagramLogo from '../assets/icons/insta.png';
import InstagramMobileLogo from '../assets/icons/insta-mobile.png';

/**
 * Menu single link
 */
const LinkItem = ({ link, title, className }) => (
  <Link
    className={`text-base title-md text-center text-white lg:hover:!text-gold font-canto-roman uppercase transition duration-300 ${className}`}
    to={link}
  >
    {title}
  </Link>
);

/**
 * Menu accordion, if item has an "items" array
 */
const AccordionItem = ({ item }) => {
  return (
    <div className="text-center group relative">
      <LinkItem {...item} />

      <div className="pt-8 hidden absolute w-max lg:group-hover:flex flex-col bg-black/90">
        {item.items.map((subItem) => (
          <LinkItem
            className="sublink p-2 pr-8 !text-left !text-gray-400 !capitalize hover:bg-white/5"
            key={subItem.link}
            {...subItem}
          />
        ))}
      </div>
    </div>
  );
};

/**
 * The whole menu list
 */
const MenuList = ({ items = [] }) => {
  return (
    <div className="lg:grow w-full flex flex-col lg:flex-row gap-8 lg:gap-8 items-center justify-center">
      {items.map((item, i) =>
        item.items ? (
          <AccordionItem key={i} item={item} />
        ) : (
          <LinkItem key={i} {...item} />
        )
      )}
    </div>
  );
};

/**
 * The main menu component
 */
const MenuMain = ({useOverridenShowMenu, overridenShowMenu, setOverridenShowMenu}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setOverridenShowMenu(!overridenShowMenu);
  };

  const shouldDisplayMenu =  useOverridenShowMenu ? overridenShowMenu : showMenu;

  document.body.style.overflowY = showMenu ? 'hidden' : 'auto';

  return (
    <>
      <MenuMainButton isOpen={shouldDisplayMenu} onClick={toggleMenu} />

      <AnimatePresence>
        {(isDesktop || shouldDisplayMenu) && (
          <motion.nav
            className="p-4 pt-32 lg:p-2 lg:sticky inset-0 h-screen lg:h-auto w-full flex flex-col items-center lg:justify-center bg-black overflow-hidden lg:overflow-visible"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {isDesktop ? (
              <div className="flex flex-row w-full items-center">
                <div className="w-[15%]">
                  {isDesktop && (
                    <Link to="/home">
                      <img
                        className="max-w-[30px] ml-[10px]"
                        src={Logo}
                        alt="Klavem, exclusive travel & experience club"
                      />
                    </Link>
                  )}
                </div>
                <div className="w-[70%]">
                  <MenuList items={menu} />
                </div>
                <div className="w-[15%] flex flex-row-reverse pr-[20px]">
                  <LinkItem {...login} />
                </div>
              </div>
            ) : (
              <>
                <Link className="lg:hidden" to="/home">
                  <img
                    className="max-w-[200px] mb-[60px] opacity-40"
                    src={MobileLogo}
                    alt="Klavem, exclusive travel & experience club"
                  />
                </Link>
                <MenuList items={menu} />
                <div className="mt-[2rem] mb-[2rem]">
                  <LinkItem {...login} />
                </div>

                <a
                  className="lg:fixed lg:w-[fit-content] w-[50%] bottom-4 left-4 flex
                       flex-col gap-2 align-center justify-center"
                  href="https://instagram.com/klavem_club?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="instagram"
                    src={InstagramMobileLogo}
                    alt="Instragram Logo"
                  />
                </a>
              </>
            )}
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default MenuMain;
